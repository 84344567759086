import React, { Component } from 'react';
import { Badge, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import dict from '../../utils/lang';

class OrdersDetailsItem extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.index = this.props.index
        this.img_path = this.props.img_path
        this.nom_prod = this.props.nom_prod
        this.variant = this.props.variant
        this.promoBadge = this.props.promoBadge
        this.qt = this.props.qt
        this.prix_variant_dispatch = this.props.prix_variant_dispatch
        this.stotal = this.props.stotal
        this.id_dispatch = this.props.id_dispatch
        this.id_order = this.props.id_order
        this.getOrderDetail = this.props.getOrderDetail
        this.etat = this.props.etat
        this.showNotification = this.props.showNotification
        this.state = {
            dropdownOpen: false,
        };
    }
    dropdownToggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }
    returnProd(id_dispatch, query) {
        return fetch('https://ec.mydzstore.com/api/returnproduct', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_dispatch: id_dispatch,
                id_order: this.id_order,
                query: query
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.showNotification(data)
                    this.getOrderDetail(this.id_order)
                } else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }
    render() {
        let promoBadge = this.promoBadge === true ? <Badge className="badge-warning">promo</Badge> : ''
        return (
            <tr key={this.index}>
                <td><img src={this.img_path} style={{ height: 50 }} alt="prod" /></td>
                <td>{this.nom_prod}<br></br>{this.variant}</td>
                <td>{promoBadge}</td>
                <td className="text-right">{this.qt}</td>
                <td className="text-right">{this.prix_variant_dispatch} {dict[this.lang].currency}</td>
                <td className="text-right">{this.stotal} {dict[this.lang].currency}</td>
                <td>
                    {/* ON RECEIVED */}
                    {
                        this.etat === "received" &&
                        <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={(e) => this.dropdownToggle(e)}
                        >
                            <DropdownToggle className="btn-outline-secondary btn-round btn-icon" >
                                <i className="nc-icon nc-refresh-69" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => this.returnProd(this.id_dispatch, "in")} tag="a">Retour dans le stock</DropdownItem>
                                <DropdownItem onClick={() => this.returnProd(this.id_dispatch, "out")} tag="b">Retour hors stock</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    }
                </td>
            </tr>
        );
    }
}

export default OrdersDetailsItem;
