import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Input } from "reactstrap";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import NotificationAlert from "react-notification-alert";

class ProdStock extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.id_prod = this.props.match.params.id_prod
        this.qtToChange = 0
        this.state = {
            stockList: [],
            isLoading: true
        };
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    setFormData(event) {
        this.qtToChange = event.target.value
    }
    handleQtChange(id_variant, query, qt_virtuel) {
        if (query === "delete" && qt_virtuel < this.qtToChange) {
            let data = []
            let message = this.lang === "fr" ? "La quantité à retirer ne doit pas être supérieure à la quantité virtuelle restante" : "يجب ألا تكون الكمية المراد سحبها أكبر من الكمية الافتراضية المتبقية"
            data['place'] = "tc"
            data['message'] = message
            data['type'] = "warning"
            data['icon'] = "nc-icon nc-bell-55"
            data['autoDismiss'] = 2
            this.showNotification(data)
        }
        else {
            return fetch('https://ec.mydzstore.com/api/handleqtchange', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    id_variant: id_variant,
                    query: query,
                    qt: this.qtToChange
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    if (data.reponse === "true") {
                        this.loadStock()

                    }
                })
                .catch((error) => console.error(error));
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }

    listStockVariants() {
        if (this.state.stockList.length === 0) {
            return (
                <p>{dict[this.lang].noStock}</p>
            )
        } else {
            return (
                <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            <th></th>
                            <th>{dict[this.lang].reference}</th>
                            <th className="text-right">{dict[this.lang].qtReal}</th>
                            <th className="text-right">{dict[this.lang].qtVirt}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.stockList.map((value, index) => {
                            let qt_virtuel = parseInt(value.qt_virtuel)
                            let qt_reel = parseInt(value.qt_reel)
                            let stockWarnClass = ""
                            if (qt_virtuel === 0) {
                                stockWarnClass = 'text-danger'
                            }
                            else if (qt_virtuel > 0 && qt_virtuel < 5) {
                                stockWarnClass = 'text-warning'
                            }

                            let promo = value.promo === "1" ? <span className="badge badge-warning">promo</span> : ''
                            let variant = value.second_label !== null ? value.first_label + ' / ' + value.second_label : value.first_label
                            return (
                                <tr key={index} className={stockWarnClass}>
                                    <td>{promo}</td>
                                    <td>{variant}</td>
                                    <td className="text-right">{qt_reel}</td>
                                    <td className="text-right">{qt_virtuel}</td>
                                    <td className="float-right d-flex">
                                        <Input style={{ width: 100 }} className="text-center mr-1" type="number" name="qt" onChange={(event) => this.setFormData(event)} />
                                        <Button className="btn btn-round btn-icon btn-sm btn-primary" onClick={() => this.handleQtChange(value.id_variant, 'add')}><i className="nc-icon nc-simple-add"></i></Button>
                                        <Button className="btn btn-round btn-icon btn-sm btn-outline-danger ml-1" onClick={() => this.handleQtChange(value.id_variant, 'delete', qt_virtuel)}><i className="nc-icon nc-simple-delete"></i></Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    loadStock() {
        return fetch('https://ec.mydzstore.com/api/getstock', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_prod: this.id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ stockList: data.stockList, isLoading: false })

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadStock()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].stock}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listStockVariants()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProdStock;
