import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalFooter, ModalBody } from "reactstrap";
import dict from "utils/lang";
import Loader from '../components/Loader/Loader';
import NotificationAlert from "react-notification-alert";
import banxy from '../assets/img/banxy.svg'
import paypal from '../assets/img/paypal.png'
import baridimob from '../assets/img/baridimob.png'

class Upgrade extends React.Component {
  constructor(props) {
    super(props)
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.notifRef = React.createRef()
    this.state = {
      offersList: [],
      isLoading: true,
      offerData: [],
      openModal: false
    };
  };
  //SHOW NOTIFICATION FUNCTION
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  getOffers() {
    return fetch('https://ec.mydzstore.com/api/listoffres', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token')
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "false") {
          this.showNotification(data)
        } else {
          this.setState({ offersList: data, isLoading: false })
        }

      })
      .catch((error) => console.error(error));
  }
  getOfferDetails() {
    return fetch('https://ec.mydzstore.com/api/offre', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token')
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "false") {
          this.showNotification(data)
        } else {
          this.setState({ offerData: data[0] })
        }

      })
      .catch((error) => console.error(error));
  }
  offerMigration(offer) {
    this.setState({ openModal: !this.state.openModal })
  }
  componentDidMount() {
    this.getOffers()
    this.getOfferDetails()
  }
  render() {
    const dirLang = this.lang === "ar" ? "arabic" : ""
    return (
      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row className={dirLang}>
          <Col className="ml-auto mr-auto" md="12">
            <Modal isOpen={this.state.openModal} >
              <ModalBody className="text-center">
                <p>{dict[this.lang].askMigration}</p>
                <img src={banxy} alt="banxy" style={{ height: 50 }} className="" />
                <img src={paypal} alt="paypal" style={{ height: 50 }} className="ml-4 mr-4" />
                <img src={baridimob} alt="baridimob" style={{ height: 50 }} className="" />
              </ModalBody>
              <ModalFooter>
                <Button className="btn-success btn-round" onClick={() => this.setState({ openModal: !this.state.openModal })}>{dict[this.lang].validate}</Button>
                <Button className="btn-danger btn-round" onClick={() => this.setState({ openModal: !this.state.openModal })}>{dict[this.lang].refuseOrder}</Button>
              </ModalFooter>
            </Modal>














            <Card className="card-upgrade">
              {/* DISPLAY LOADER */}
              {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                <Loader />
              </div>}
              <CardHeader className="text-center">
                <CardTitle tag="h4">MyDzStore Pro</CardTitle>
                <p className="card-category">
                  {dict[this.lang].upgradeText}
                </p>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr><th />
                      {
                        this.state.offersList.map((offre, index) => {
                          return (
                            <th key={index} className="text-center">{offre.nom_offre}</th>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td>{dict[this.lang].limitProd}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let limit_prod = offre.limit_prod
                          offre.limit_prod === "0" && (limit_prod = "Illimité")
                          return (
                            <td key={index} className="text-center">{limit_prod}</td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].limitPhotos}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let limit_photos = offre.limit_photos
                          offre.limit_photos === "0" && (limit_photos = "Illimité")
                          return (
                            <td key={index} className="text-center">{limit_photos}</td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].limitSlides}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let limit_slides = offre.limit_slides
                          offre.limit_slides === "0" && (limit_slides = "Illimité")
                          return (
                            <td key={index} className="text-center">{limit_slides}</td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].couponCode}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let couponCode = offre.coupon_code === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={couponCode} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].orders}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let orders = offre.orders === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={orders} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].customisable}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let customisable = offre.customisable === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={customisable} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].fbChat}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let api_messenger = offre.api_messenger === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={api_messenger} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].domainNameTitle}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let domain = offre.domain === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={domain} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].ssl}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let cssl = offre.cssl === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={cssl} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].apiShipping}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let api_livraison = offre.api_livraison === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={api_livraison} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr><td>{dict[this.lang].dashboard}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let dashboard = offre.dashboard === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={dashboard} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td>{dict[this.lang].catalogPlugin}</td>
                      {
                        this.state.offersList.map((offre, index) => {
                          let fb_catalog = offre.fb_catalog === "Oui" ? "nc-icon nc-check-2 text-success" : "nc-icon nc-simple-remove text-danger"
                          return (
                            <td key={index} className="text-center"><i className={fb_catalog} /></td>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td></td>
                      {
                        this.state.offersList.map((offre, index) => {
                          return (
                            <td key={index} className="text-center">{offre.prix_offre} {dict[this.lang].currency} / {dict[this.lang].month}</td>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td></td>
                      {
                        this.state.offersList.map((offre, index) => {
                          return (
                            <td key={index} className="text-center">{offre.prix_offre_euro} € / {dict[this.lang].month}</td>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td></td>
                      {/* <td className="text-center">
                        {this.state.offerData.offre === "Limited" ?
                          <Button
                            className="btn-round disabled btn-primary"
                          >
                            Offre Actuelle
                          </Button>
                          :
                          <Button
                            className="btn-round btn-primary"
                            onClick={(e) => this.offerMigration('Limited')}
                          >
                            Migrer vers limited
                          </Button>
                        }

                      </td> */}
                      <td className="text-center">
                        {this.state.offerData.offre === "Store" ?
                          <Button
                            className="btn-round disabled btn-warning"
                          >
                            Offre Actuelle
                          </Button>
                          :
                          <Button
                            className="btn-round btn-warning"
                            onClick={(e) => this.offerMigration('Store')}
                          >
                            Migrer vers Store
                          </Button>
                        }
                      </td>
                      <td className="text-center">
                        {this.state.offerData.offre === "Pro" ?
                          <Button
                            className="btn-round disabled btn-success"
                          >
                            Offre Actuelle
                          </Button>
                          :
                          <Button
                            className="btn-round btn-success"
                            onClick={(e) => this.offerMigration('Pro')}
                          >
                            Migrer vers Pro
                          </Button>
                        }
                      </td>
                      {/* <td className="text-center">
                        {this.state.offerData.offre === "Business" ?
                          <Button
                            className="btn-round disabled btn-danger"
                          >
                            Offre Actuelle
                          </Button>
                          :
                          <Button
                            className="btn-round btn-danger"
                            onClick={(e) => this.offerMigration('Business')}
                          >
                            Migrer vers Business
                          </Button>
                        }
                      </td> */}
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Upgrade;
