import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button } from "reactstrap";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

class Coupons extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            couponsList: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    loadCoupons() {
        return fetch('https://ec.mydzstore.com/api/getcoupons', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ couponsList: data.couponsList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST PRODUCTS IN TABLE
    listCoupons() {
        if (this.state.couponsList.length === 0) {
            return (
                <p>Vous n'avez encore créé aucun coupon</p>
            )
        } else {
            return (
                <Table >
                    <thead className="text-primary">
                        <tr>
                            <th>{dict[this.lang].couponCode}</th>
                            <th>{dict[this.lang].discountType}</th>
                            <th className="text-right">{dict[this.lang].discountValue}</th>
                            <th className="text-right">{dict[this.lang].expireDate}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.couponsList.map((value, index) => {
                            let expireClass = value.expired === 1 ? 'text-danger' : ''
                            let discount_type = value.discount_type
                            return (
                                <tr key={index} className={expireClass}>
                                    <td>{value.code_coupon}</td>
                                    <td>{dict[this.lang][discount_type]}</td>
                                    <td className="text-right">{value.discount_value}</td>
                                    <td className="text-right">{value.date_exp}</td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/coupons/coupon-form/" + value.id_coupon,
                                                id_coupon: value.id_coupon
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                        <Button className="btn-sm btn-round btn-icon btn-outline-danger" onClick={() => this.removeCoupon(value.id_coupon)}>
                                            <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //REMOVE PRODUCT BY ID
    removeCoupon(id_coupon) {
        return fetch('https://ec.mydzstore.com/api/delcoupon', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_coupon: id_coupon
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.loadCoupons()
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadCoupons()
    }

    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Link to={
                            { pathname: "/coupons/coupon-form/add" }
                        }><Button className="btn-round" color="primary">{dict[this.lang].addCoupon}</Button></Link>
                    </Col>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].couponCode}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listCoupons()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Coupons;
