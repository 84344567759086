import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Input, FormGroup } from "reactstrap";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';

class MyShopContact extends Component {
    constructor(props) {
        super(props)
        this.form = {}
        this.showNotification = this.props.showNotification
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            contactInfos: [],
            isLoading: false,
            cgvText: ""
        }
    }
    setFormData(event) {
        this.form[event.target.id] = event.target.value
    }
    setCgvText(text) {
        this.form['cgv'] = text
    }
    //SET CONTACT INFOS
    setContactInfos() {
        return fetch('https://ec.mydzstore.com/api/setcontactinfos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    //GET CONTACT INFOS
    getContactInfos() {
        this.setState({ isLoading: true })
        return fetch('https://ec.mydzstore.com/api/getcontactinfos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {

                } else {
                    this.setState({ contactInfos: data[0], isLoading: false, cgvText: data[0].cgv })
                    this.form = data[0]
                }
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getContactInfos()
    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}

                <CardHeader>

                </CardHeader>

                <CardBody>
                    <Row>
                        {/* bloc contact */}
                        <Col md="6">
                            <CardTitle tag="h5">{dict[this.lang].contactInfosTitle}</CardTitle>
                            <p>{dict[this.lang].contactInfosText}</p>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="adresse">
                                            {dict[this.lang].address}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.adresse} placeholder="Adresse" type="text" id="adresse" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="adresse_ar">
                                            {dict[this.lang].addressAr}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.adresse_ar} placeholder="Adresse" type="text" id="adresse_ar" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="email">
                                            {dict[this.lang].email}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.email} placeholder="Email" type="email" id="email" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="tel">
                                            {dict[this.lang].phone}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.tel} placeholder="Téléphone" type="phone" id="tel" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="wats">
                                            {dict[this.lang].wats}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.wats} placeholder="213XXXXXXXXX" type="phone" id="wats" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="link_fb">
                                            {dict[this.lang].linkFb}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.link_fb} placeholder="Lien" type="text" id="link_fb" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="link_insta">
                                            {dict[this.lang].linkInsta}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.link_insta} placeholder="Lien" type="text" id="link_insta" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="link_twitter">
                                            {dict[this.lang].linkTwitter}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.link_twitter} placeholder="Lien" type="text" id="link_twitter" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="brand_name">
                                            {dict[this.lang].brandName}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.brand_name} placeholder="Nom de la boutique" type="text" id="brand_name" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <label htmlFor="lon">
                                            {dict[this.lang].lon}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.lon} placeholder="3.2458" type="text" id="lon" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <label htmlFor="lat">
                                            {dict[this.lang].lat}
                                        </label>
                                        <Input defaultValue={this.state.contactInfos.lat} placeholder="36.5211" type="text" id="lat" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        {/* bloc cgv */}
                        <Col md="6">
                            <CardTitle tag="h5">{dict[this.lang].cgvTitle}</CardTitle>
                            <blockquote>
                                <p className="blockquote blockquote-info"><i className="nc-icon nc-alert-circle-i" style={{ fontSize: 24 }}></i><br></br>{dict[this.lang].cgvText}</p>
                            </blockquote>
                            <ReactQuill
                                theme="snow"
                                value={this.state.cgvText}
                                onChange={(text) => this.setCgvText(text)}
                                style={{
                                    height: 180
                                }}
                            />
                        </Col>
                        {/* BUTTON */}
                        <Col md="12">
                            <Button onClick={() => this.setContactInfos()} style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default MyShopContact;
