import React, { useState, createContext } from 'react'
export const LoginContext = createContext()

export const LoginProvider = ({ children }) => {
    const [userData, setUserData] = useState(false)

    return (
        <LoginContext.Provider value={{ userData, setUserData }}>
            {children}
        </LoginContext.Provider>
    )
}