import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Table, Button, Input } from "reactstrap";
import dict from '../../utils/lang';
import '../../assets/css/myshop.css';
import Loader from '../Loader/Loader';

class MyShopDomain extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.domain_name = ""
        this.showNotification = this.props.showNotification
        this.state = {
            offerData: [],
            isLoading: true
        };
    }
    setFormData(event) {
        this.domain_name = event.target.value
    }
    getOfferDetails() {
        return fetch('https://ec.mydzstore.com/api/offre', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.setState({ offerData: data[0], isLoading: false })
                }

            })
            .catch((error) => console.error(error));
    }
    showTempLink() {
        let storeName = window.sessionStorage.getItem('storeName').toLocaleLowerCase()
        let link = "https://shop.mydzstore.com/" + storeName
        if (this.state.offerData.sub_domain === "0" && this.state.offerData.domain_name_enabled === "0") {
            return (
                <div>
                    <p>{dict[this.lang].tempLinkText}</p>
                    <div className="text-center">
                        <b><a target="_blank" rel="noreferrer" href={link}>shop.mydzstore.com/{storeName}</a></b>
                    </div>
                    <p></p>
                    <div className="text-center">
                        <p>{dict[this.lang].subdomainLinkTextWait}</p>
                        <b>{storeName}.mydzstore.com</b>
                    </div>

                </div>
            )
        } else if (this.state.offerData.sub_domain === "1" && this.state.offerData.domain_name_enabled === "0") {
            link = "http://" + storeName + ".mydzstore.com"
            return (
                <div>
                    <p>{dict[this.lang].subdomainLinkText}</p>
                    <div className="text-center">
                        <b><a target="_blank" rel="noreferrer" href={link}> {storeName}.mydzstore.com</a></b>
                    </div>
                </div>
            )
        }
        else if (this.state.offerData.domain_name_enabled === "1") {
            let domaine = "https://" + this.state.offerData.domain_name
            return (
                <div className="col-md-12 text-center">
                    <a target="_blank" rel="noreferrer" href={domaine} className="h5">{this.state.offerData.domain_name}</a>
                </div>
            )
        }
    }
    askDomainValidation() {
        return fetch('https://ec.mydzstore.com/api/askdomainvalidation', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                domain_name: this.domain_name
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.showNotification(data)
                    this.getOfferDetails()
                }

            })
            .catch((error) => console.error(error));
    }
    domainLink() {
        let domaine = "https://" + this.state.offerData.domain_name
        if (this.state.offerData.domain_name_enabled === "0" && this.state.offerData.domain_name !== null) {

            return (
                <div className="col-md-12 text-center">
                    <a target="_blank" rel="noreferrer" href={domaine} className="h5">{this.state.offerData.domain_name}</a>
                    <p className="text-warning">{dict[this.lang].validationOnProgress}</p>
                </div>
            )
        }
        else if (this.state.offerData.domain_name_enabled === "1" && this.state.offerData.domain_name !== null) {
            return (
                <div className="col-md-12 text-center">
                    <a target="_blank" rel="noreferrer" href={domaine} className="h5">{this.state.offerData.domain_name}</a>
                </div>
            )
        }
        else {
            return (
                <div className="col-md-12">
                    <Input defaultValue={this.state.offerData.domain_name} placeholder="lien du siteweb" type="text" id="domain_name" onChange={(event) => this.setFormData(event)} />
                    <Button onClick={() => this.askDomainValidation()} className="btn btn-round btn-primary mr-2">
                        <i className="nc-icon nc-check-2"></i> {dict[this.lang].validate}
                    </Button>
                </div>
            )
        }
    }
    componentDidMount() {
        this.getOfferDetails()
    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].storeAddress}</CardTitle>
                </CardHeader>
                <CardBody>
                    {this.showTempLink()}
                    <CardTitle className="mt-4" tag="h5">{dict[this.lang].domainNameTitle}</CardTitle>
                    <p>{dict[this.lang].domainNameText}</p>
                    {this.domainLink()}
                </CardBody>
            </Card>
        );
    }
}

export default MyShopDomain;
