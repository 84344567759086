import '../assets/css/login.min.css';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Input, Row, Button, NavLink, Spinner } from "reactstrap";
import Footer from "../components/Footer/Footer";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';

class Login extends Component {
  constructor(props) {
    super(props)
    this.email = ""
    this.password = ""
    this.setUserData = this.props.setUserData
    this.notifRef = React.createRef();
    this.state = {
      isLoading: false,
      curLang: window.sessionStorage.getItem('lang')
    }
  }
  // ValidateEmail(inputText) {
  //   var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if (inputText.match(mailformat)) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }
  setEmail = (event) => {
    this.email = event.target.value
  }
  setPassword = (event) => {
    this.password = event.target.value
  }
  showNotification(data) {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  loginFunc() {
    this.setState({ isLoading: true })
    return fetch('https://ec.mydzstore.com/api/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.email,
        password: this.password
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === 'true') {
          window.sessionStorage.setItem('loggedIn', "true")
          window.sessionStorage.setItem('storeName', data.storeName)
          window.sessionStorage.setItem('email', data.email)
          window.sessionStorage.setItem('session_token', data.session_token)
          window.sessionStorage.setItem('expired', data.expired)
          this.setState({ isLoading: false })
          this.setUserData(true)
        } else if (data.reponse === 'false') {
          this.setState({ isLoading: false })
          this.showNotification(data)
        }
      })
      .catch((error) => console.error(error));
  }
  displayLoader() {
    if (this.state.isLoading) {
      return (
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
          <Spinner color="primary" />
        </div>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Button onClick={() => this.loginFunc()} color="info" className="btn-round mb-3 btn-block">{dict[this.state.curLang].login}</Button>
          <NavLink href="#" onClick={() => this.props.history.push("/register")} >{dict[this.state.curLang].createStore}</NavLink>
        </div>
      )
    }
  }
  switchLang = (event) => {
    event.preventDefault()
    let lang = window.sessionStorage.getItem('lang')
    if (lang === null || lang === "ar") {
      window.sessionStorage.setItem('lang', 'fr')
      this.setState({ curLang: "fr" })
    }
    else if (lang === "fr") {
      window.sessionStorage.setItem('lang', 'ar')
      this.setState({ curLang: "ar" })
    }
  }
  render() {
    return (
      <div className="wrapper wrapper-full-page ps">
        <NotificationAlert ref={this.notifRef} />
        <div className="full-page section-image">
          <div className="login-page">
            <div className="container">
              <Row>
                <div className="ml-auto mr-auto col-md-6 col-lg-4">
                  <form action="" method="" className="form">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader className="text-right">
                          <a style={{ fontSize: 18 }} href="#lang" onClick={(event) => this.switchLang(event)}>{this.state.curLang === "fr" ? "العربية" : "Français"}</a>
                          <h3 className="header text-center">{dict[this.state.curLang].login}</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-single-02"></i></span>
                          </div>
                          <Input placeholder="Email" type="email" className="form-control" onChange={this.setEmail} />
                        </div>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-key-25"></i></span>
                          </div>
                          <Input placeholder="Mot de passe..." type="password" autoComplete="off" className="form-control" onChange={this.setPassword} />
                        </div>
                      </CardBody>
                      <CardFooter>
                        {this.displayLoader()}
                      </CardFooter>
                    </Card>
                  </form>
                </div>
              </Row>
            </div>
            <div className="full-page-background"></div>
          </div>
        </div>
        <Footer fluid />
      </div>

    )
  }
}

export default Login;