import React, { Component } from 'react';
import { Row, Col, Button, Input, Modal, ModalFooter, ModalBody } from "reactstrap";
import CatUploader from './CatUploader';
import dict from "utils/lang";

class CatItem extends Component {
    constructor(props) {
        super(props)
        this.lib_cat = this.props.libCat
        this.lib_cat_ar = this.props.libCatAr
        this.id_cat = this.props.idCat
        this.index = this.props.index
        this.form = { cat_fr: this.lib_cat, cat_ar: this.lib_cat_ar }
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.showNotification = this.props.showNotification
        this.removeCat = this.props.removeCat
        this.getCat = this.props.getCat
        this.state = {
            editDisabled: true,
            openModal: false,
        }
    }
    saveCat() {
        return fetch('https://ec.mydzstore.com/api/savecat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form,
                id_cat: this.id_cat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.setState({ editDisabled: true })
                this.getCat()
            })
            .catch((error) => console.error(error));
    }
    setFormDataFr(event) {
        this.form["cat_fr"] = event.target.value
        if ((this.form['cat_fr'] !== this.lib_cat || this.form['cat_ar'] !== this.lib_cat_ar) && (this.form['cat_fr'] !== "" && this.form['cat_ar'] !== "")) {
            this.setState({ editDisabled: false })
        }
        else {
            this.setState({ editDisabled: true })
        }
    }
    setFormDataAr(event) {
        this.form["cat_ar"] = event.target.value
        if ((this.form['cat_fr'] !== this.lib_cat || this.form['cat_ar'] !== this.lib_cat_ar) && (this.form['cat_fr'] !== "" && this.form['cat_ar'] !== "")) {
            this.setState({ editDisabled: false })
        }
        else {
            this.setState({ editDisabled: true })
        }
    }
    displayImgUploader() {
        this.setState({ openModal: !this.state.openModal })
    }
    render() {
        let id_fr = "cat_fr_" + this.index
        let id_ar = "cat_ar_" + this.index
        return (
            <Row className="align-items-center">

                <Modal isOpen={this.state.openModal} >
                    <ModalBody className="text-center">
                        <CatUploader
                            id_cat={this.id_cat}
                            lib_cat={this.lib_cat}
                            lib_cat_ar={this.lib_cat_ar}
                            showNotification={this.showNotification}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-danger btn-round" onClick={() => this.setState({ openModal: !this.state.openModal })}>{dict[this.lang].refuseOrder}</Button>
                    </ModalFooter>
                </Modal>


                <Col md="4">
                    <Input defaultValue={this.lib_cat} type="text" id={id_fr} onChange={(event) => this.setFormDataFr(event)} />
                </Col>
                <Col md="4">
                    <Input defaultValue={this.lib_cat_ar} type="text" id={id_ar} onChange={(event) => this.setFormDataAr(event)} />
                </Col>
                <Col md="2" className="d-flex">
                    <Button disabled={this.state.editDisabled} onClick={() => this.saveCat()} className="btn-sm btn-icon btn-round" color="success"><i className="nc-icon nc-check-2" /></Button>
                    <Button onClick={() => this.removeCat(this.id_cat)} className="btn-icon btn-round btn-sm btn-outline-danger"><i className="nc-icon nc-simple-remove" /></Button>

                </Col>
                <Col md="2" className="d-flex">
                    <Button onClick={() => this.displayImgUploader(this.id_cat, this.lib_cat, this.lib_cat_ar)} className="btn-sm btn-icon btn-round btn-outline-primary" ><i className="nc-icon nc-camera-compact" /></Button>
                </Col>
            </Row>
        );
    }
}

export default CatItem;
