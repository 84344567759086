import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Spinner, Button } from "reactstrap";
import dict from '../../utils/lang';
import ImageUploader from "react-images-upload";

class ProdPics extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.showNotification = this.props.showNotification
        this.id_prod = this.props.id_prod
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            isLoading: false,
            isLoadingPics: true,
            picturesList: [],
            image: null
        };
    }
    //ON DROP IMAGE UPLOAD
    onDrop(pictureFiles, pictureDataURLs) {
        if (pictureFiles.length > 0) {
            this.setState({ image: pictureFiles[0] })
        } else {
            this.setState({ image: null })
        }
    }
    //SEND IMAGE TO SERVER 
    sendImage(image) {
        this.setState({ isLoading: true, image: null })
        let formdata = new FormData();
        formdata.append('file', image)
        formdata.append('session_token', window.sessionStorage.getItem('session_token'))
        formdata.append('id_prod', this.id_prod)
        return fetch('https://ec.mydzstore.com/api/setprodpic', {
            method: 'POST',
            body: formdata
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.setState({ isLoading: false })
                this.showNotification(data)
                this.loadImage()
            })
            .catch((error) => console.error(error));
    }
    //GET IMAGE FROM SERVER
    loadImage() {
        return fetch('https://ec.mydzstore.com/api/getprodpic', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_prod: this.id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ picturesList: data.picturesList, isLoadingPics: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //REMOVE IMAGE
    removeImage(id_photo) {
        return fetch('https://ec.mydzstore.com/api/delprodpic', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_photo: id_photo
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.showNotification(data)
                    this.loadImage()
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }
    //DISPLAY LOADER
    displayUploaderLoader() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    //DISPLAY LOADER
    displayPhotosLoader() {
        if (this.state.isLoadingPics) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    componentDidMount() {
        this.loadImage()
    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].picsTitle}</CardTitle>
                </CardHeader>
                <CardBody>
                    <p>{dict[this.lang].picsText}</p>
                    <Row>
                        <Col md="6">
                            <Row className="justify-content-center">
                                {this.displayPhotosLoader()}
                                {this.state.picturesList.length !== 0 &&
                                    this.state.picturesList.map((value, index) => {
                                        return (
                                            <Col key={index} md="3" className="mb-2">
                                                < img key={index} style={{ width: "100%", height: 100 }} src={value.img_path} alt="prod" />
                                                <Button className="btn-danger btn-sm btn-icon btn-round" style={{ position: 'absolute', bottom: 0, right: 0, margin: 0 }}><i className="nc-icon nc-simple-remove" onClick={() => this.removeImage(value.id_photo)}></i></Button>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        <Col md="6" className="d-flex justify-content-center flex-column">
                            {this.displayUploaderLoader()}
                            {!this.state.isLoading &&
                                <ImageUploader
                                    withPreview={true}
                                    withIcon={true}
                                    buttonText={dict[this.lang].importImage}
                                    onChange={this.onDrop}
                                    imgExtension={[".jpg", ".gif", ".png"]}
                                    maxFileSize={250000}
                                    label="Max 250 Ko. Formats:jpg, png"
                                    singleImage={true}
                                    fileTypeError={dict[this.lang].fileTypeError}
                                    fileSizeError={dict[this.lang].fileSizeError}
                                />
                            }
                            {this.state.image !== null &&
                                <Button onClick={() => this.sendImage(this.state.image)} className="btn-round btn-sm" color="primary">{dict[this.lang].btnUploadLogoText}</Button>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default ProdPics;
