import React, { Component } from 'react';
import { Row, Col, Button, Input, Modal, ModalFooter, ModalBody } from "reactstrap";
import SCatUploader from './SCatUploader';
import dict from "utils/lang";

class ScatItem extends Component {
    constructor(props) {
        super(props)
        this.lib_scat = this.props.libScat
        this.lib_scat_ar = this.props.libScatAr
        this.id_scat = this.props.idScat
        this.id_cat = this.props.idCat
        this.index = this.props.index
        this.form = { scat_fr: this.lib_scat, scat_ar: this.lib_scat_ar }
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.showNotification = this.props.showNotification
        this.removeScat = this.props.removeScat
        this.getScat = this.props.getScat
        this.state = {
            disabled: true,
            openModal: false
        }
    }
    displayImgUploader() {
        this.setState({ openModal: !this.state.openModal })
    }
    saveScat() {
        return fetch('https://ec.mydzstore.com/api/savescat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form,
                id_scat: this.id_scat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.setState({ disabled: true })
                this.getScat(this.id_cat)
            })
            .catch((error) => console.error(error));
    }
    setFormDataFr(event) {
        this.form["scat_fr"] = event.target.value
        if ((this.form['scat_fr'] !== this.lib_scat || this.form['scat_ar'] !== this.lib_scat_ar) && (this.form['scat_fr'] !== "" && this.form['scat_ar'] !== "")) {
            this.setState({ disabled: false })
        }
        else {
            this.setState({ disabled: true })
        }
    }
    setFormDataAr(event) {
        this.form["scat_ar"] = event.target.value
        if ((this.form['scat_fr'] !== this.lib_scat || this.form['scat_ar'] !== this.lib_scat_ar) && (this.form['scat_fr'] !== "" && this.form['scat_ar'] !== "")) {
            this.setState({ disabled: false })
        }
        else {
            this.setState({ disabled: true })
        }
    }
    render() {
        let id_fr = "scat_fr_" + this.index
        let id_ar = "scat_ar_" + this.index
        return (
            <Row className="align-items-center">
                <Modal isOpen={this.state.openModal} >
                    <ModalBody className="text-center">
                        <SCatUploader
                            id_scat={this.id_scat}
                            lib_scat={this.lib_scat}
                            lib_scat_ar={this.lib_scat_ar}
                            showNotification={this.showNotification}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-danger btn-round" onClick={() => this.setState({ openModal: !this.state.openModal })}>{dict[this.lang].refuseOrder}</Button>
                    </ModalFooter>
                </Modal>
                <Col md="4">
                    <Input defaultValue={this.lib_scat} type="text" id={id_fr} onChange={(event) => this.setFormDataFr(event)} />
                </Col>
                <Col md="4">
                    <Input defaultValue={this.lib_scat_ar} type="text" id={id_ar} onChange={(event) => this.setFormDataAr(event)} />
                </Col>
                <Col md="2" className="d-flex">
                    <Button disabled={this.state.disabled} onClick={() => this.saveScat()} className="btn-sm btn-icon btn-round" color="success"><i className="nc-icon nc-check-2" /></Button>
                    <Button onClick={() => this.removeScat(this.id_scat)} className="btn-sm btn-icon btn-round btn-outline-danger"><i className="nc-icon nc-simple-remove" /></Button>
                </Col>
                <Col md="2" className="d-flex">
                    <Button onClick={() => this.displayImgUploader(this.id_cat, this.lib_cat, this.lib_cat_ar)} className="btn-sm btn-icon btn-round btn-outline-primary" ><i className="nc-icon nc-camera-compact" /></Button>
                </Col>
            </Row>
        );
    }
}

export default ScatItem;
