import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Button } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import messenger from '../assets/img/messenger.png';
import facebook from '../assets/img/facebook.png';
import { Link } from 'react-router-dom'

class CouponFiche extends Component {
    constructor(props) {
        super(props)
        this.notifRef = React.createRef()
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.form = {}
        this.state = {
            contactInfos: [],
            isLoading: true,
            disabled: true,
            offerData: [],
            link: "",
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }

    //SET FORM DATA
    setFormData = (event) => {

        this.form[event.target.id] = event.target.value
        if (event.target.value === "") {
            delete this.form[event.target.id]
        }
    }
    //SAVE FB CHAT ID
    saveFbChatId() {
        return fetch('https://ec.mydzstore.com/api/savefbchatid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    //GET CONTACT INFOS
    getContactInfos() {
        return fetch('https://ec.mydzstore.com/api/getcontactinfos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.setState({ contactInfos: data[0], isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //GET OFFER DETAILS
    getOfferDetails() {
        return fetch('https://ec.mydzstore.com/api/offre', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.setState({ offerData: data[0] })
                }

            })
            .catch((error) => console.error(error));
    }
    //UPDATE DATA SOURCE FOR FB CATALOG
    updateDataSource() {
        return fetch('https://ec.mydzstore.com/api/updatedatasource', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.setState({ link: data.link })
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getContactInfos()
        this.getOfferDetails()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="6">
                        <Card style={{ height: '100%' }}>
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <CardTitle tag="h5">
                                    {dict[this.lang].messengerPlugin}
                                </CardTitle>
                                <img src={messenger} style={{ height: 36 }} alt="messenger_logo" />

                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12">
                                        <blockquote>
                                            <p className="blockquote blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].messengerPluginText }}></p>
                                        </blockquote>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <label htmlFor="fb_chat_id">
                                                {/* {dict[this.lang].couponCode} */}
                                            </label>
                                            <Input defaultValue={this.state.contactInfos.fb_chat_id} type="text" id="fb_chat_id" onChange={(event) => this.setFormData(event)} style={{ fontWeight: 'bold' }} />
                                        </FormGroup>
                                    </Col>

                                    <Col md="12" className="d-flex justify-content-center">
                                        <Button onClick={() => this.saveFbChatId()} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* FB CATALOG */}
                    <Col md="6">
                        <Card style={{ height: '100%' }}>
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <CardTitle tag="h5">
                                    {dict[this.lang].catalogPlugin}
                                </CardTitle>
                                <img src={facebook} style={{ height: 36 }} alt="fb_logo" />

                            </CardHeader>
                            <CardBody className="text-center">
                                <Row>
                                    <Col md="12">
                                        <blockquote>
                                            <p className="blockquote blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].catalogPluginText }}></p>
                                        </blockquote>
                                    </Col>
                                </Row>

                                {
                                    this.state.offerData.offre === "Pro" &&
                                    <Row>
                                        <Col md="12">
                                            <Input defaultValue={this.state.link} readOnly type="text" id="fb_catalog_id" style={{ fontWeight: 'bold' }} />
                                        </Col>
                                        <Col md="12" className="d-flex justify-content-center">
                                            <Button onClick={() => this.updateDataSource()} className="btn-round" color="primary">{dict[this.lang].catalogPluginButtonText}</Button>
                                        </Col>
                                    </Row>
                                }
                                {
                                    this.state.offerData.offre !== "Pro" &&
                                    <Row>
                                        <Col md="12" className="d-flex justify-content-center">
                                            <Link to={
                                                {
                                                    pathname: "/upgrade",
                                                }
                                            }>
                                                <Button className="btn btn-round btn-primary mr-2">
                                                    <i className="nc-icon nc-spaceship"></i> {dict[this.lang].migrate}
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md="12">
                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/VWY3IRfeh4E" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default CouponFiche;
