import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Input, FormGroup, Label } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import CatItem from '../components/Categories/CatItem';
import ScatItem from '../components/Categories/ScatItem';
import Select from 'react-select'

class Categories extends Component {
    constructor(props) {
        super(props)
        this.form = {}
        this.notifRef = React.createRef();
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            isLoading: true,
            catList: [],
            catListOptions: [],
            scatList: [],
            disabled: true,
            disabledScat: true,
            id_cat: ""
        }
    }

    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }


    //SAVE CATEGORIE
    saveCat() {
        return fetch('https://ec.mydzstore.com/api/savecat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getCat()
            })
            .catch((error) => console.error(error));
    }
    //SAVE SUB CATEGORIE
    saveScat() {
        return fetch('https://ec.mydzstore.com/api/savescat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getScat(this.state.id_cat)
            })
            .catch((error) => console.error(error));
    }
    //REMOVE CATEGORIE
    removeCat = (id_cat) => {
        return fetch('https://ec.mydzstore.com/api/removecat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_cat: id_cat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.showNotification(data)
                    this.getCat()
                }
            })
            .catch((error) => console.error(error));
    }
    //REMOVE SUB CATEGORIE  
    removeScat = (id_scat) => {
        return fetch('https://ec.mydzstore.com/api/removescat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_scat: id_scat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getScat(this.state.id_cat)
            })
            .catch((error) => console.error(error));
    }
    setFormData(event) {
        this.form[event.target.id] = event.target.value
        if ((this.form['cat_fr'] === "" || this.form['cat_ar'] === "") || (this.form['cat_ar'] === undefined || this.form['cat_fr'] === undefined)) {
            this.setState({ disabled: true })
        }
        else {
            this.setState({ disabled: false })
        }
    }
    setFormDataScat(event) {
        this.form[event.target.id] = event.target.value
        if ((this.form['scat_fr'] === "" || this.form['scat_ar'] === "") || (this.form['scat_ar'] === undefined || this.form['scat_fr'] === undefined) || this.form['id_cat'] === "0" || this.form['id_cat'] === undefined) {
            this.setState({ disabledScat: true })
        }
        else {
            this.setState({ disabledScat: false })
        }
    }
    setFormDataIdCat(event) {
        this.setState({ id_cat: event.value })
        this.form[event.inputId] = event.value
        if ((this.form['scat_fr'] === "" || this.form['scat_ar'] === "") || (this.form['scat_ar'] === undefined || this.form['scat_fr'] === undefined) || this.form['id_cat'] === "0" || this.form['id_cat'] === undefined) {
            this.setState({ disabledScat: true })
        }
        else {
            this.setState({ disabledScat: false })
        }

        this.getScat(event.value)
    }
    //LOAD CATEGORIE
    getCat = () => {
        return fetch('https://ec.mydzstore.com/api/getcat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else {
                    let catListOptions = []
                    data.catList.forEach((value) => {
                        catListOptions.push({ value: value.id_cat, label: value.lib_cat + ' | ' + value.lib_cat_ar, inputId: "id_cat" })
                    })
                    this.setState({ catList: [] })
                    this.setState({ catListOptions: catListOptions, catList: data.catList, isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }

    //LOAD SUB CATEGORIE
    getScat = (id_cat) => {
        return fetch('https://ec.mydzstore.com/api/getscat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_cat: id_cat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false, scatList: [] })
                } else {
                    this.setState({ scatList: [] })
                    this.setState({ scatList: data.scatList, isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }

    //DISPLAY CATEGORIES LIST
    displayCatList() {
        if (this.state.catList.length === 0) {
            return (
                <p>{dict[this.lang].noCat}</p>
            )
        }
        else {
            let array = []
            this.state.catList.forEach((value, index) => {
                array.push(<CatItem libCat={value.lib_cat} libCatAr={value.lib_cat_ar} idCat={value.id_cat} key={index} index={index} showNotification={this.showNotification} removeCat={this.removeCat} getCat={this.getCat} />)
            })
            return (
                array
            )
        }
    }

    //DISPLAY SUB CATEGORIES LIST
    displayScatList() {
        if (this.state.scatList.length === 0) {
            return (
                <p>{dict[this.lang].noScat}</p>
            )
        }
        else {
            let array = []
            this.state.scatList.forEach((value, index) => {
                array.push(<ScatItem libScat={value.lib_scat} libScatAr={value.lib_scat_ar} idScat={value.id_scat} key={value.id_scat} index={index} showNotification={this.showNotification} removeScat={this.removeScat} getScat={this.getScat} idCat={value.id_cat} />)
            })
            return (
                array
            )
        }
    }
    componentDidMount() {
        this.getCat()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="6">
                        <Card style={{ height: '100%' }}>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h5">{dict[this.lang].categories}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row className="justify-content-center">
                                    <Col md="6">
                                        <FormGroup>
                                            <label htmlFor="cat_fr">
                                                {dict[this.lang].catFr}
                                            </label>
                                            <Input type="text" id="cat_fr" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label htmlFor="cat_ar">
                                                {dict[this.lang].catAr}
                                            </label>
                                            <Input type="text" id="cat_ar" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <Button disabled={this.state.disabled} onClick={() => this.saveCat()} style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                                    </Col>
                                </Row>
                                <hr></hr>
                                {this.displayCatList()}
                            </CardBody>
                        </Card>
                    </Col>

                    {/* SOUS-CATEGORIES */}

                    <Col md="6">
                        <Card style={{ height: '100%' }}>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h5">{dict[this.lang].subCategories}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row className="justify-content-center">

                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="id_cat">{dict[this.lang].categories}</Label>
                                            <Select options={this.state.catListOptions} onChange={(event) => this.setFormDataIdCat(event)} id="id_cat" />
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                            <label htmlFor="scat_fr">
                                                {dict[this.lang].scatFr}
                                            </label>
                                            <Input defaultValue="" type="text" id="scat_fr" onChange={(event) => this.setFormDataScat(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label htmlFor="scat_ar">
                                                {dict[this.lang].scatAr}
                                            </label>
                                            <Input defaultValue="" type="text" id="scat_ar" onChange={(event) => this.setFormDataScat(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        {this.state.disabledScat &&
                                            <Button disabled style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                                        }
                                        {!this.state.disabledScat &&
                                            <Button onClick={() => this.saveScat()} style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                                        }
                                    </Col>
                                </Row>
                                <hr></hr>
                                {this.displayScatList()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div >
        );
    }
}

export default Categories;
