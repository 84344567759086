import React, { Component } from 'react';
import { Table, Row, Col, Button, Input } from "reactstrap";
import dict from '../utils/lang';
import NotificationAlert from "react-notification-alert";
import { Link } from 'react-router-dom';

class DeliveryFees extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.form = {}
        this.state = {
            feesList: [],
        }
    }

    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD FEES
    loadWilayasFees() {
        return fetch('https://ec.mydzstore.com/api/loadwilayafees', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.setState({ feesList: data.feesList, isLoading: false })
            })
            .catch((error) => console.error(error));
    }
    //UPDATE FEES
    updateFees(event) {
        return fetch('https://ec.mydzstore.com/api/updatewilayafee', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                code_wil: event.target.id,
                fee: event.target.value
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadWilayasFees()
    }
    render() {
        return (
            <div className="content" >
                <NotificationAlert ref={this.notifRef} />
                <Row className="d-flex justify-content-center">
                    <Col md="6">
                        <Table className="table-bordered" responsive>
                            <thead className="text-secondary">
                                <tr>
                                    <th>Wilaya</th>
                                    <th className="text-right">ولاية</th>
                                    <th className="text-right">{dict[this.lang].fees} ({dict[this.lang].currency})</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.feesList.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.NAME}</td>
                                            <td className="text-right">{value.NAME_AR}</td>
                                            <td className="text-right">
                                                <Input id={value.CODE} type="number" style={{ width: 80 }} defaultValue={value.fee} onBlur={(event) => this.updateFees(event)} />
                                            </td>
                                            <td>
                                                <Link to={
                                                    {
                                                        pathname: "/delivery/fees/communes/" + value.CODE,
                                                        code_wilaya: value.CODE
                                                    }
                                                }><Button className="btn-sm btn-round btn-outline-primary">{dict[this.lang].communes}</Button></Link>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default DeliveryFees;
