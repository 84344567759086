import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import dict from '../../utils/lang';
import Loader from '../Loader/Loader'

const labels = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jui", "Août", "Sept", "Oct", "Nov", "Dec"]
const options = {
    plugins: {
        legend: { display: false },
        tooltip: { enabled: false },
    },
    scales: {
        y: {
            ticks: {
                color: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 5,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                padding: 20,
                color: "#9f9f9f",
            },
        },
    },
}
class ChartRevenue extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            data: [],
            isLoading: true,
        };
    }
    getRevenueChart() {
        this.setState({ isLoading: true })
        return fetch('https://ec.mydzstore.com/api/getrevenuechart', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else if (data.reponse === "true") {
                    this.setState({ isLoading: false, data: data.dataRevenue })
                }
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getRevenueChart()
    }
    render() {
        return (
            <Card>
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].revenuePerMonth}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Line
                        data={(canvas) => {
                            return {
                                labels: labels,
                                datasets: [
                                    {
                                        borderColor: "#6bd098",
                                        backgroundColor: "#6bd09825",
                                        pointRadius: 0,
                                        pointHoverRadius: 0,
                                        borderWidth: 3,
                                        tension: 0.4,
                                        fill: true,
                                        data: this.state.data
                                    }
                                ],
                            };
                        }}
                        options={options}
                        width={400}
                        height={100}
                    />
                </CardBody>
                <CardFooter>
                    <hr />
                    <div className="stats" onClick={() => this.getRevenueChart()}>
                        <i className="fas fa-history" /> {dict[this.lang].refresh}
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default ChartRevenue;
