import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Table, Button, Input } from "reactstrap";
import MyShopContact from './MyShopContact';
import MyShopLogo from './MyShopLogo';
import MyShopBg from './MyShopBg';
import MyShopSlides from './MyShopSlides';
import MyShopColors from './MyShopColors';
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import '../assets/css/myshop.css';
import MyShopDomain from 'components/MyShop/MyShopDomain';
import Loader from '../components/Loader/Loader';

class MyShop extends Component {
    constructor(props) {
        super(props)
        this.notifRef = React.createRef();
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.domain_name = ""
        this.state = {
            offerData: [],
            isLoading: true
        }
    }

    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    getOfferDetails() {
        return fetch('https://ec.mydzstore.com/api/offre', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.setState({ offerData: data[0], isLoading: false })
                }

            })
            .catch((error) => console.error(error));
    }
    renewOffer() {
        return fetch('https://ec.mydzstore.com/api/renewoffer', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    this.showNotification(data)
                    this.getOfferDetails()
                    window.sessionStorage.setItem('expired', 'false')
                }

            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getOfferDetails()
    }
    render() {

        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content" >
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="6">
                        <Row style={{ height: '100%' }}>
                            <Col md="12">
                                <MyShopDomain showNotification={this.showNotification} />
                            </Col>
                            <Col md="12" className="mt-4">
                                <MyShopBg showNotification={this.showNotification} />
                            </Col>
                        </Row>
                    </Col>
                    {/* TABLEAU OFFRE ACTUELLE */}
                    <Col md="6">
                        <Card style={{ height: '100%' }}>
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h5">{dict[this.lang].myOffer}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th className="text-center">{this.state.offerData.offre}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{dict[this.lang].limitProd}</td>
                                            {
                                                this.state.offerData.limit_prod === "0"
                                                    ?
                                                    <td className="text-center">Illimité</td>
                                                    :
                                                    <td className="text-center">{this.state.offerData.limit_prod}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].limitPhotos}</td>
                                            {
                                                this.state.offerData.limit_photos === "0"
                                                    ?
                                                    <td className="text-center">Illimité</td>
                                                    :
                                                    <td className="text-center">{this.state.offerData.limit_photos}</td>
                                            }
                                        </tr>
                                        <tr><td>{dict[this.lang].limitSlides}</td>
                                            {
                                                this.state.offerData.limit_slides === "0"
                                                    ?
                                                    <td className="text-center">Illimité</td>
                                                    :
                                                    <td className="text-center">{this.state.offerData.limit_slides}</td>
                                            }
                                        </tr>
                                        <tr><td>{dict[this.lang].fbChat}</td>
                                            {
                                                this.state.offerData.api_messenger === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].domainNameTitle}</td>
                                            {
                                                this.state.offerData.domain === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].ssl}</td>
                                            {
                                                this.state.offerData.cssl === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].apiShipping}</td>
                                            {
                                                this.state.offerData.api_livraison === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].dashboard}</td>
                                            {
                                                this.state.offerData.dashboard === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].catalogPlugin}</td>
                                            {
                                                this.state.offerData.fb_catalog === "Oui"
                                                    ?
                                                    <td className="text-center"><i className="nc-icon nc-check-2 text-success" /></td>
                                                    :
                                                    <td className="text-center"><i className="nc-icon nc-simple-remove text-danger" /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{dict[this.lang].expireDate}</td>
                                            <td><b>{this.state.offerData.offre_exp}</b></td>
                                        </tr>
                                        <tr>
                                            <td />
                                            {
                                                this.state.offerData.prix_offre === "0"
                                                    ?
                                                    <td className="text-center"> {dict[this.lang].free}</td>
                                                    :
                                                    <td className="text-center">{this.state.offerData.prix_offre}DA/mois</td>
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                                {
                                    this.state.offerData.offre === "Store" &&
                                    <blockquote>
                                        <p className="blockquote blockquote-warning">
                                            <i className="nc-icon nc-alert-circle-i" style={{ fontSize: 24 }}></i>
                                            <br></br>
                                            {dict[this.lang].renewText}
                                        </p>
                                    </blockquote>

                                }
                                {
                                    this.state.offerData.offre === "Store" &&
                                    <Button
                                        style={{ width: "100%" }}
                                        className="btn-round"
                                        color="secondary"
                                        onClick={() => this.renewOffer()}
                                    >
                                        {dict[this.lang].renew}
                                    </Button>
                                }
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="12" className="mt-4">
                        <MyShopContact showNotification={this.showNotification} />
                    </Col>
                    <Col md="6" className="mt-4">
                        <MyShopLogo showNotification={this.showNotification} />
                    </Col>
                    <Col md="6" className="mt-4">
                        <MyShopColors showNotification={this.showNotification} />
                    </Col>
                    <Col md="12" className="mt-4">
                        <MyShopSlides showNotification={this.showNotification} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MyShop;
