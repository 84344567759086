import React, { Component } from 'react';
import { Table, Row, Col, Input } from "reactstrap";
import dict from '../utils/lang';
import NotificationAlert from "react-notification-alert";

class DeliveryFees extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.form = {}
        this.code_wilaya = this.props.match.params.code_wilaya
        this.state = {
            feesList: [],
        }
    }

    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD FEES
    loadCommunesFees() {
        return fetch('https://ec.mydzstore.com/api/loadcommunesfees', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                code_wilaya: this.code_wilaya
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.setState({ feesList: data.feesList, isLoading: false })
            })
            .catch((error) => console.error(error));
    }
    //UPDATE FEES
    updateFees(event) {
        return fetch('https://ec.mydzstore.com/api/updatecommunesfee', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                code_commune: event.target.id,
                fee: event.target.value
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadCommunesFees()
    }
    render() {
        return (
            <div className="content" >
                <NotificationAlert ref={this.notifRef} />
                <Row className="d-flex justify-content-center">
                    <Col md="6">
                        <Table className="table-bordered">
                            <thead className="text-secondary">
                                <tr>
                                    <th>{dict[this.lang].communes}</th>
                                    <th className="text-right">{dict[this.lang].fees} ({dict[this.lang].currency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.feesList.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.lib_commune}</td>
                                            <td>
                                                <Input id={value.code_commune} type="number" style={{ width: 80 }} defaultValue={value.fee} onBlur={(event) => this.updateFees(event)} />
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default DeliveryFees;
