import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Input, FormGroup, CustomInput, Label } from "reactstrap";
import dict from '../../utils/lang';
import Select from 'react-select'
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';

class ProdInfos extends Component {
    constructor(props) {
        super(props)
        this.form = {}
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.showNotification = this.props.showNotification
        this.setIdProd = this.props.setIdProd
        this.state = {
            disabled: false,
            catList: [],
            scatList: [],
            id_cat_select: {},
            id_scat_select: {},
            isLoading: true,
            product: [],
            nouveauState: false,
            masquerState: false,
            id_prod: this.props.id_prod,
            desc_prod: ""
        }
    }
    //SET FORM DATA
    setFormData = (event) => {
        if (event.value !== undefined) {
            this.form[event.id] = event.value
            if (event.value === "") {
                delete this.form[event.id]
            }
        } else {
            this.form[event.target.id] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.id]
            }
        }
    }
    //SET PRODUCT DESCRIPTION
    setDescProd(text) {
        this.form['desc_prod'] = text
    }
    //HANDLE SWITCH OPTIONS
    handleSwitch(event) {
        if (event.target.id === "masquer") {
            this.setState({ masquerState: !this.state.masquerState })
        } else if (event.target.id === "nouveau") {
            this.setState({ nouveauState: !this.state.nouveauState })
        }
    }
    //LOAD CATEGORIES
    getCat() {
        return fetch('https://ec.mydzstore.com/api/getcat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else {
                    let catList = []
                    data.catList.forEach(value => {
                        catList.push({ value: value.id_cat, label: value.lib_cat + ' | ' + value.lib_cat_ar, id: "id_cat" })
                    })
                    this.setState({ catList: catList })
                }
            })
            .catch((error) => console.error(error));
    }
    //LOAD SUB CATEGORIE
    getScat(id_cat) {
        delete this.form.id_scat
        return fetch('https://ec.mydzstore.com/api/getscat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_cat: id_cat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ scatList: [] })
                } else {
                    let scatList = []
                    data.scatList.forEach((value) => {
                        scatList.push({ value: value.id_scat, label: value.lib_scat + ' | ' + value.lib_scat_ar, id: "id_scat" })
                    })
                    this.setState({ scatList: scatList })
                }
            })
            .catch((error) => console.error(error));
    }
    //SAVE PRODUCT TO DATABASE
    saveProd() {
        this.state.nouveauState === true ? this.form['nouveau'] = 1 : this.form['nouveau'] = 0
        this.state.masquerState === true ? this.form['masquer'] = 1 : this.form['masquer'] = 0

        return fetch('https://ec.mydzstore.com/api/saveprod', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                if (data.id_prod !== undefined) {
                    this.setIdProd(data.id_prod)
                }
            })
            .catch((error) => console.error(error));
    }
    //UPDATE PRODUCT DATA
    updateProd() {
        this.state.nouveauState === true ? this.form['nouveau'] = 1 : this.form['nouveau'] = 0
        this.state.masquerState === true ? this.form['masquer'] = 1 : this.form['masquer'] = 0
        return fetch('https://ec.mydzstore.com/api/saveprod', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form,
                id_prod: this.state.id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    //GET PRODUCT DATA
    getProdData() {
        if (this.state.id_prod !== "add") {
            return fetch('https://ec.mydzstore.com/api/getproddata', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    id_prod: this.state.id_prod
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    let product = data.productData[0]
                    const defaultValue = this.state.catList.find(opt => opt.value === product.id_cat);
                    this.setState({ product: product, id_cat_select: defaultValue, desc_prod: product.desc_prod })


                    this.getScat(product.id_cat).then(() => {
                        const defaultValue = this.state.scatList.find(opt => opt.value === product.id_scat);
                        this.setState({ id_scat_select: defaultValue })
                    })

                    this.form.nom_prod = product.nom_prod
                    this.form.id_cat = product.id_cat
                    this.form.id_scat = product.id_scat
                    this.form.marque_prod = product.marque_prod
                    this.form.masquer = product.masquer
                    this.form.nouveau = product.nouveau
                    this.form.desc_prod = product.desc_prod

                    let masquer = product.masquer === "1" ? true : false
                    let nouveau = product.nouveau === "1" ? true : false

                    this.setState({ masquerState: masquer, nouveauState: nouveau })
                })
                .catch((error) => console.error(error));
        }
    }
    componentDidMount() {
        this.getCat().then(() => {
            this.getProdData()
        })

    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].prodInfos}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <FormGroup>
                                <Label for="exampleCheckbox">{dict[this.lang].options}</Label>
                                <div>
                                    <CustomInput type="switch" id="masquer" name="masquer" label={dict[this.lang].hideProduct} inline onChange={(event) => this.handleSwitch(event)} checked={this.state.masquerState} />
                                    <CustomInput type="switch" id="nouveau" name="nouveau" label={dict[this.lang].setNewBadge} inline onChange={(event) => this.handleSwitch(event)} checked={this.state.nouveauState} />
                                </div>
                            </FormGroup>

                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="nom_prod">
                                    {dict[this.lang].designation}
                                </label>
                                <Input defaultValue={this.state.product.nom_prod} type="text" id="nom_prod" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="id_cat">
                                    {dict[this.lang].categories}
                                </label>
                                <Select value={this.state.id_cat_select} options={this.state.catList} onChange={(event) => { this.setState({ id_cat_select: event }); this.setFormData(event); this.getScat(event.value) }} isSearchable={false} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="id_scat">
                                    {dict[this.lang].subCategories}
                                </label>
                                <Select options={this.state.scatList} value={this.state.id_scat_select} onChange={(event) => { this.setState({ id_scat_select: event }); this.setFormData(event) }} isSearchable={false} />
                            </FormGroup>
                        </Col>

                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="marque_prod">
                                    {dict[this.lang].brand}
                                </label>
                                <Input defaultValue={this.state.product.marque_prod} type="text" id="marque_prod" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <ReactQuill
                                theme="snow"
                                value={this.state.desc_prod}
                                onChange={(text) => this.setDescProd(text)}
                                style={{
                                    height: 180
                                }}
                            />
                        </Col>
                        <Col md="12">
                            {
                                this.state.id_prod !== "add" &&
                                <Button disabled={this.state.disabled} onClick={() => this.updateProd()} style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].update}</Button>
                            }
                            {
                                this.state.id_prod === "add" &&
                                <Button disabled={this.state.disabled} onClick={() => this.saveProd()} style={{ width: "100%" }} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                            }

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default ProdInfos;
