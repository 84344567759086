import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import dict from '../../utils/lang';
import Loader from '../Loader/Loader'

const options = {
    plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
    },
    maintainAspectRatio: false,
    pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
    },
    scales: {
        y: {
            ticks: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
            },
        },
    },
}

class ChartBestSellers extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            isLoading: false,
            data: [],
            labels: [],
        };
    }
    getBestSellers() {
        this.setState({ isLoading: true })
        return fetch('https://ec.mydzstore.com/api/getbestsellers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else if (data.reponse === "true") {
                    this.setState({ isLoading: false, data: data.data, labels: data.labels })
                }
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getBestSellers()
    }
    render() {
        return (
            <Card>
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].bestSellers}</CardTitle>
                    <p className="card-category">{dict[this.lang].top5}</p>
                </CardHeader>
                <CardBody style={{ height: "266px" }}>
                    <Pie
                        data={(canvas) => {
                            return {
                                labels: this.state.labels,
                                datasets: [
                                    {
                                        label: "Ventes",
                                        pointRadius: 0,
                                        pointHoverRadius: 0,
                                        backgroundColor: ["#E4572E", "#137547", "#54428E", "#A8C686", "#235789"],
                                        borderWidth: 0,
                                        data: this.state.data,
                                    },
                                ],
                            };
                        }}
                        options={options}
                    />
                </CardBody>
                <CardFooter>
                    {/* <div className="legend">
                        <i className="fa fa-circle text-primary" /> Opened{" "}
                        <i className="fa fa-circle text-warning" /> Read{" "}
                        <i className="fa fa-circle text-danger" /> Deleted{" "}
                        <i className="fa fa-circle text-gray" /> Unopened
                    </div> */}
                    <hr />
                    <div className="stats" onClick={() => this.getBestSellers()}>
                        <i className="fas fa-history" /> {dict[this.lang].refresh}
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default ChartBestSellers;
