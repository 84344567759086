import '../assets/css/login.min.css';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Input, Row, Button, Spinner } from "reactstrap";
import Stepper from 'react-stepper-horizontal'
import Footer from "../components/Footer/Footer";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';

class Register extends Component {
    constructor(props) {
        super(props)
        this.steps = [{
            title: 'Information de connexion',
            href: '#',
            onClick: (e) => {
                e.preventDefault()
                this.setState({ step: 0 })
            }
        }, {
            title: 'Informations client',
            href: '#',
            onClick: (e) => {
                e.preventDefault()
                this.setState({ step: 1 })
            }
        }, {
            title: 'Information sur la boutique',
            href: '#',
            onClick: (e) => {
                e.preventDefault()
                this.setState({ step: 2 })
            }
        }]
        this.setUserData = this.props.setUserData
        this.notifRef = React.createRef();
        //STATE
        this.state = {
            isLoading: false,
            curLang: "fr",
            step: 0,
            email: "",
            password: "",
            passwordConf: "",
            name: "",
            prenom: "",
            phone: "",
            storeName: "",
            storeType: ""
        }
    }
    ValidateEmail(inputText) {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (inputText.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }
    setEmail = (event) => {
        //this.email = event.target.value
        this.setState({ email: event.target.value })
    }
    setPassword = (event) => {
        this.setState({ password: event.target.value })
    }
    setPasswordConf = (event) => {
        this.setState({ passwordConf: event.target.value })
    }
    setName = (event) => {
        this.setState({ name: event.target.value })
    }
    setPrenom = (event) => {
        this.setState({ prenom: event.target.value })
    }
    setPhone = (event) => {
        this.setState({ phone: event.target.value })
    }
    setStoreName = (event) => {
        this.setState({ storeName: event.target.value })
    }
    setStoreType = (event) => {
        this.setState({ storeType: event.target.value })
    }
    setNextStep() {
        this.setState({ step: this.state.step + 1 })
    }

    showNotification(data) {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    registerFunc() {
        if (this.ValidateEmail(this.state.email)) {
            if (this.state.password === this.state.passwordConf) {
                if (this.state.name === "" || this.state.prenom === "" || this.state.storeName === "" || this.state.storeType === "") {
                    let array = { place: "tc", message: "Tous les champs doivent être renseignés", type: "warning", icon: "nc-icon nc-simple-remove", autoDismiss: 3 }
                    this.showNotification(array)
                } else {
                    this.setState({ isLoading: true })
                    return fetch('https://ec.mydzstore.com/api/register', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: this.state.email,
                            password: this.state.password,
                            name: this.state.name,
                            prenom: this.state.prenom,
                            phone: this.state.phone,
                            storeName: this.state.storeName,
                            storeType: this.state.storeType
                        })
                    })
                        .then((reponse) => reponse.json())
                        .then((data) => {
                            if (data.type === 'true') {
                                window.sessionStorage.setItem('loggedIn', "true")
                                window.sessionStorage.setItem('storeName', data.storeName)
                                window.sessionStorage.setItem('email', data.email)
                                window.sessionStorage.setItem('session_token', data.session_token)
                                this.setState({ isLoading: false })
                                this.setUserData(true)
                            } else {
                                this.setState({ isLoading: false })
                                this.showNotification(data)
                            }
                        })
                        .catch((error) => console.error(error));
                }
            }
            else {
                let array = { place: "tc", message: "Les deux mots de passe ne correspondent pas", type: "warning", icon: "nc-icon nc-simple-remove", autoDismiss: 3 }
                this.showNotification(array)
            }

        } else {
            let array = { place: "tc", message: "Email non valide", type: "warning", icon: "nc-icon nc-simple-remove", autoDismiss: 3 }
            this.showNotification(array)
        }
    }
    displayLoader() {
        if (this.state.isLoading) {
            return (
                <div>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <Spinner color="primary" />
                    </div>
                    <div className="mt-4" style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <p>Création du compte...</p>
                        <p>Création de la base de données...</p>
                        <p>Création de la boutique...</p>
                        <p>Configuration du server...</p>
                    </div>
                </div>
            )
        } else {
            if (this.state.step === 2) {
                return (
                    <Button onClick={() => this.registerFunc()} color="info" className="btn-round mb-3 btn-block">{dict[this.state.curLang].createStore}</Button>
                )
            } else {
                return (
                    <Button onClick={() => this.setNextStep()} color="info" className="btn-round mb-3 btn-block">{dict[this.state.curLang].next}</Button>
                )
            }

        }
    }
    switchLang = (event) => {
        event.preventDefault()
        let lang = window.sessionStorage.getItem('lang')
        if (lang === null || lang === "ar") {
            window.sessionStorage.setItem('lang', 'fr')
            this.setState({ curLang: "fr" })
        }
        else if (lang === "fr") {
            window.sessionStorage.setItem('lang', 'ar')
            this.setState({ curLang: "ar" })
        }
    }
    setDefaultLang() {
        let lang = window.sessionStorage.getItem('lang')
        if (lang === null) {
            window.sessionStorage.setItem('lang', 'fr')
        }
    }
    showStep() {
        if (this.state.step === 0) {
            return (
                <div className="mt-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-globe"></i></span>
                        </div>
                        <Input value={this.state.email} name="email" placeholder="Email" type="email" className="form-control" onChange={this.setEmail} />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-key-25"></i></span>
                        </div>
                        <Input value={this.state.password} placeholder="Mot de passe..." type="password" autoComplete="off" className="form-control" onChange={this.setPassword} />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-key-25"></i></span>
                        </div>
                        <Input value={this.state.passwordConf} name="passconf" placeholder="Confirmez mot de passe..." type="password" autoComplete="off" className="form-control" onChange={this.setPasswordConf} />
                    </div>
                </div>
            )
        }
        else if (this.state.step === 1) {
            return (
                <div className="mt-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-badge"></i></span>
                        </div>
                        <Input value={this.state.name} name="name" placeholder="Nom" type="text" className="form-control" onChange={this.setName} />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-single-02"></i></span>
                        </div>
                        <Input value={this.state.prenom} placeholder="Prénom" type="text" className="form-control" onChange={this.setPrenom} />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-mobile"></i></span>
                        </div>
                        <Input value={this.state.phone} name="phone" placeholder="Téléphone (facultatif)" type="tel" className="form-control" onChange={this.setPhone} />
                    </div>
                </div>
            )
        }
        else if (this.state.step === 2) {
            return (
                <div className="mt-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-shop"></i></span>
                        </div>
                        <Input value={this.state.storeName} placeholder="Nom de la boutique (Didoushop, bazardz...)" type="text" className="form-control" onChange={this.setStoreName} autoComplete="off" />
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-alert-circle-i"></i></span>
                        </div>
                        <Input value={this.state.storeType} type="select" className="form-control" onChange={this.setStoreType}>
                            <option></option>
                            <option>Vêtements</option>
                            <option>Electro. Hi-Tech</option>
                            <option>Meubles</option>
                            <option>Autres</option>
                        </Input>
                    </div>
                </div>
            )
        }
    }
    componentDidMount() {
        this.setDefaultLang()
    }
    render() {
        return (
            <div className="wrapper wrapper-full-page ps">
                <NotificationAlert ref={this.notifRef} />
                <div className="full-page section-image">
                    <div className="login-page">
                        <div className="container">
                            <Row>
                                <div className="ml-auto mr-auto col-md-6">
                                    <form action="" method="" className="form">
                                        <Card className="card-login">
                                            <CardHeader>
                                                <CardHeader className="text-right">
                                                    <a href="#a" onClick={(event) => this.switchLang(event)}>{this.state.curLang === "fr" ? "العربية" : "Français"}</a>
                                                    <h3 className="header text-center">{dict[this.state.curLang].createStore}</h3>
                                                </CardHeader>
                                            </CardHeader>
                                            <CardBody>
                                                <Stepper steps={this.steps} activeStep={this.state.step} />
                                                {this.showStep()}
                                            </CardBody>
                                            <CardFooter>
                                                {this.displayLoader()}
                                            </CardFooter>
                                        </Card>
                                    </form>
                                </div>
                            </Row>
                        </div>
                        <div className="full-page-background"></div>
                    </div>
                </div>
                <Footer fluid />
            </div>

        )
    }
}

export default Register;