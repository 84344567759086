import Dashboard from "views/Dashboard.js";
import MyShop from "views/MyShop.js";
import Icons from "views/Icons.js";
import UpgradeToPro from "views/Upgrade.js";
import Categories from "views/Categories";
import Products from "views/Products";
import ProdFiche from "views/ProdFiche";
import ProdStock from "views/ProdStock";
import Orders from "views/Orders";
import OrdersArchived from "views/OrdersArchived";
import OrdersDetails from "views/OrdersDetails";
import Coupons from "views/Coupons";
import CouponFiche from "views/CouponFiche";
import Delivery from "views/Delivery";
import DeliveryFees from "views/DeliveryFees";
import DeliveryFeesCommunes from "views/DeliveryFeesCommunes";
import Plugins from "views/Plugins";

var routes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    name_ar: "لوحة القيادة",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
  },
  {
    path: "/myshop",
    name: "Ma boutique",
    name_ar: "متجري",
    icon: "nc-icon nc-shop",
    component: MyShop,
  },
  {
    path: "/orders",
    name: "Commandes",
    name_ar: "الطلبيات",
    icon: "nc-icon nc-cart-simple",
    component: Orders,
    display: true
  },
  {
    path: "/orders/archived",
    name: "Commandes Archivés",
    name_ar: "الطلبيات من الأرشيف",
    icon: "nc-icon nc-cart-simple",
    component: OrdersArchived,
    display: false
  },
  {
    path: "/orders/details/:id_order",
    name: "تفاصيل الطلب",
    name_ar: "الطلبيات من الأرشيف",
    icon: "nc-icon nc-cart-simple",
    component: OrdersDetails,
    display: false
  },
  {
    path: "/products/categories",
    name: "Catégories",
    name_ar: "الفئات",
    icon: "nc-icon nc-bullet-list-67",
    component: Categories,
    display: false
  },
  {
    path: "/products",
    name: "Produits",
    name_ar: "منتجات",
    icon: "nc-icon nc-tag-content",
    component: Products,
  },
  {
    path: "/coupons",
    name: "Codes de réduction",
    name_ar: "قسيمة التخفيض",
    icon: "nc-icon nc-money-coins",
    component: Coupons,
  },
  {
    path: "/products/product-form/:id_prod",
    name: "Fiche Produit",
    icon: "nc-icon nc-tag-content",
    component: ProdFiche,
    display: false
  },
  {
    path: "/products/product-stock/:id_prod",
    name: "Stock Produit",
    icon: "nc-icon nc-tag-content",
    component: ProdStock,
    display: false
  },
  {
    path: "/coupons/coupon-form/:id_coupon",
    name: "Codes de réduction",
    name_ar: "قسيمة التخفيض",
    icon: "nc-icon nc-money-coins",
    component: CouponFiche,
    display: false
  },
  {
    path: "/delivery",
    name: "Mode de livraison",
    name_ar: "طريقة التوصيل",
    icon: "nc-icon nc-delivery-fast",
    component: Delivery,
  },
  {
    path: "/delivery/fees",
    name: "Frais de livraison",
    name_ar: "تكاليف التوصيل",
    icon: "nc-icon nc-delivery-fast",
    component: DeliveryFees,
    display: false
  },
  {
    path: "/delivery/fees/communes/:code_wilaya",
    name: "Frais de livraison",
    name_ar: "تكاليف التوصيل",
    icon: "nc-icon nc-delivery-fast",
    component: DeliveryFeesCommunes,
    display: false
  },
  {
    path: "/plugins",
    name: "Plugins",
    name_ar: "الإضافات",
    icon: "nc-icon nc-settings",
    component: Plugins,
    display: true
  },

  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    display: false
  },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,

  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage,

  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList,

  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,

  // },
  {
    pro: true,
    path: "/upgrade",
    name: "Migrer vers Pro",
    name_ar: "الانتقال إلى Pro",
    icon: "nc-icon nc-spaceship",
    component: UpgradeToPro,

  },
];
export default routes;
