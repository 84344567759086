import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Badge } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import OrdersDetailsItem from 'components/Orders/OrdersDetailsItem';

class OrdersDetails extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.id_order = this.props.match.params.id_order
        this.state = {
            isLoading: true,
            orderInfos: [],
            orderDispatch: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD ORDERS
    getOrderDetail = (id_order) => {
        return fetch('https://ec.mydzstore.com/api/getorderdetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ orderDispatch: [] })
                    this.setState({ orderInfos: data.orderInfos, orderDispatch: data.orderDispatch, isLoading: false })

                } else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST ORDERS IN TABLE
    listDispatch() {
        if (this.state.orderDispatch.length === 0) {
            return (
                <p>{dict[this.lang].noOrder}</p>
            )
        } else {
            return (
                <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            <th></th>
                            <th>{dict[this.lang].designation}</th>
                            <th>{dict[this.lang].promo}</th>
                            <th className="text-right">{dict[this.lang].qt}</th>
                            <th className="text-right">{dict[this.lang].price}</th>
                            <th className="text-right">{dict[this.lang].totalPrice}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orderDispatch.map((value, index) => {
                            let promoBadge = value.promo === "1" ? true : false
                            let prix_variant_dispatch = parseInt(value.prix_variant_dispatch)
                            let qt = parseInt(value.qt)
                            let stotal = qt * prix_variant_dispatch
                            let variant = value.first_label
                            if (value.second_label !== null && value.second_label !== "") {
                                variant = variant + ' / ' + value.second_label
                            }
                            return (
                                <OrdersDetailsItem
                                    key={index}
                                    promoBadge={promoBadge}
                                    prix_variant_dispatch={prix_variant_dispatch}
                                    qt={qt}
                                    stotal={stotal}
                                    variant={variant}
                                    index={index}
                                    img_path={value.img_path}
                                    nom_prod={value.nom_prod}
                                    id_dispatch={value.id_dispatch}
                                    id_order={this.id_order}
                                    getOrderDetail={this.getOrderDetail}
                                    etat={this.state.orderInfos.etat}
                                    showNotification={this.showNotification}
                                />
                            )
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //CANCEL ORDER
    cancelOrder(id_order) {
        return fetch('https://ec.mydzstore.com/api/cancelorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(id_order)
            })
            .catch((error) => console.error(error));
    }
    //ACCEPT ORDER
    acceptOrder(id_order) {
        return fetch('https://ec.mydzstore.com/api/acceptorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(id_order)
            })
            .catch((error) => console.error(error));
    }
    //TERMINER PREPARATION COMMANDE
    setToReadyOrder(id_order) {
        return fetch('https://ec.mydzstore.com/api/setreadyorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(id_order)
            })
            .catch((error) => console.error(error));
    }
    // ENVOYER LA COMMANDE
    sendOrder(id_order) {
        return fetch('https://ec.mydzstore.com/api/sendorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(id_order)
            })
            .catch((error) => console.error(error));
    }
    //COMMANDE LIVREE
    setToReceivedOrder(id_order) {
        return fetch('https://ec.mydzstore.com/api/setreceivedorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_order: id_order
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(id_order)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getOrderDetail(this.id_order)
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        const textAlign1 = this.lang === "ar" ? "text-right" : "text-left"
        const textAlign2 = this.lang === "ar" ? "text-left" : "text-right"
        let stotal = parseInt(this.state.orderInfos.stotal)
        let discount = parseInt(this.state.orderInfos.discount)
        let total = stotal - discount
        let fee = parseInt(this.state.orderInfos.frais_livraison)
        let ttp = total + fee

        let deliveryMode = ""
        if (this.state.orderInfos.type_livraison === "1") {
            deliveryMode = dict[this.lang].inStore
        } else if (this.state.orderInfos.type_livraison === "2" || this.state.orderInfos.type_livraison === "3") {
            deliveryMode = dict[this.lang].homeDelivery
        } else if (this.state.orderInfos.type_livraison === "4") {
            deliveryMode = dict[this.lang].stopDesk
        }

        let color = "";
        switch (this.state.orderInfos.etat) {
            case "new":
                color = "badge-primary"
                break;
            case "canceled":
                color = "badge-secondary"
                break;
            case "preparing":
                color = "badge-warning"
                break;
            case "ready":
                color = "badge-success"
                break;
            case "shipping":
                color = "badge-success"
                break;
            case "received":
                color = "badge-success"
                break;

            default:
                break;
        }
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].orderInfos} <Badge className={color}>{dict[this.lang][this.state.orderInfos.etat]}</Badge></CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6" className="d-flex">
                                        <Col className={textAlign1}>
                                            <p><strong>{dict[this.lang].orderNumber}</strong></p>
                                            <p><strong>{dict[this.lang].orderDate}</strong></p>
                                            <p><strong>{dict[this.lang].customer}</strong></p>
                                            <p><strong>{dict[this.lang].phone}</strong></p>
                                            <p><strong>{dict[this.lang].email}</strong></p>
                                        </Col>
                                        <Col className={textAlign2}>
                                            <p>{this.state.orderInfos.id_order}</p>
                                            <p>{this.state.orderInfos.date_heure}</p>
                                            <p>{this.state.orderInfos.nom} {this.state.orderInfos.prenom}</p>
                                            <p>{this.state.orderInfos.phone}</p>
                                            <p>{this.state.orderInfos.email}</p>
                                        </Col>


                                    </Col>
                                    <Col md="6" className="d-flex">
                                        <Col className={textAlign1}>
                                            <p><strong>{dict[this.lang].deliveryMode}</strong></p>
                                            <p><strong>{(this.state.orderInfos.type_livraison === "2" || this.state.orderInfos.type_livraison === "3") ? dict[this.lang].orderAddress : ''}</strong></p>
                                            <p><strong>{this.state.orderInfos.type_livraison !== "1" ? dict[this.lang].wilaya : ''}</strong></p>
                                            <p><strong>{this.state.orderInfos.type_livraison !== "1" ? dict[this.lang].communes : ''}</strong></p>
                                        </Col>
                                        <Col className={textAlign2}>

                                            <p className="text-danger">{deliveryMode}</p>
                                            <p>{(this.state.orderInfos.type_livraison === "2" || this.state.orderInfos.type_livraison === "3") ? this.state.orderInfos.order_adresse : ''}</p>
                                            <p>{this.state.orderInfos.type_livraison !== "1" ? this.state.orderInfos.order_wilaya_lib : ''}</p>
                                            <p>{this.state.orderInfos.type_livraison !== "1" ? this.state.orderInfos.order_commune_lib : ''}</p>
                                        </Col>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>




                    {/* CONTENU COMMANDE */}
                    <Col md="7">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].orders}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listDispatch()}

                                {/* ON NEW ORDER */}
                                {this.state.orderInfos.etat === "new" || this.state.orderInfos.etat === "preparing" ? <Button className="btn-outline-danger btn-round" onClick={() => this.cancelOrder(this.id_order)}>{dict[this.lang].refuseOrder}</Button> : ''}

                                {this.state.orderInfos.etat === "new" ? <Button className="btn-success btn-round float-right" onClick={() => this.acceptOrder(this.id_order)}>{dict[this.lang].acceptOrder}</Button> : ''}

                                {/* ON PREPARING ORDER */}
                                {this.state.orderInfos.etat === "preparing" && this.state.orderInfos.type_livraison !== "1" ? <Button className="btn-success btn-round float-right" onClick={() => this.sendOrder(this.id_order)}>{dict[this.lang].shipping}</Button> : ''}

                                {this.state.orderInfos.etat === "preparing" && this.state.orderInfos.type_livraison === "1" ? <Button className="btn-success btn-round float-right" onClick={() => this.setToReadyOrder(this.id_order)}>{dict[this.lang].ready}</Button> : ''}

                                {/* ON SHIPPING OR READT ORDER */}
                                {this.state.orderInfos.etat === "shipping" ? <Button className="btn-success btn-round float-right" onClick={() => this.setToReceivedOrder(this.id_order)}>{dict[this.lang].received}</Button> : ''}
                                {this.state.orderInfos.etat === "ready" ? <Button className="btn-success btn-round float-right" onClick={() => this.setToReceivedOrder(this.id_order)}>{dict[this.lang].inStore}</Button> : ''}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="5">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4"></CardTitle>
                            </CardHeader>
                            <CardBody className="d-flex">
                                <Col md="8" className={textAlign1}>
                                    <p><strong>{dict[this.lang].totalPrice}</strong></p>
                                    <p><strong>{this.state.orderInfos.code_coupon !== "" ? dict[this.lang].couponCode : ''}</strong></p>
                                    <p><strong>{dict[this.lang].discount}</strong></p>
                                    <p><strong>{dict[this.lang].total}</strong></p>
                                    <p><strong>{dict[this.lang].fee}</strong></p>
                                    <hr></hr>
                                    <p><strong>{dict[this.lang].totalToPay}</strong></p>
                                </Col>
                                <Col md="4" className={textAlign2}>
                                    <p>{stotal} {dict[this.lang].currency}</p>
                                    <p>{this.state.orderInfos.code_coupon !== "" ? this.state.orderInfos.code_coupon : ''}</p>
                                    <p>{discount} {dict[this.lang].currency}</p>
                                    <p>{total} {dict[this.lang].currency}</p>
                                    <p>{fee} {dict[this.lang].currency}</p>
                                    <hr></hr>
                                    <p className="text-success"><strong>{ttp} {dict[this.lang].currency}</strong></p>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default OrdersDetails;
