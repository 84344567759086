import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Spinner, Button } from "reactstrap";
import dict from '../../utils/lang';
import ImageUploader from "react-images-upload";

class SCatUploader extends Component {
    constructor(props) {
        super(props)
        this.form = {}
        this.id_scat = this.props.id_scat
        this.lib_scat = this.props.lib_scat
        this.lib_scat_ar = this.props.lib_scat_ar
        this.showNotification = this.props.showNotification
        this.lang = window.sessionStorage.getItem('lang')
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            logo: null,
            isLoading: true,
            image: null
        }
    }
    setFormData(event) {
        this.form[event.target.id] = event.target.value
    }
    //ON DROP IMAGE UPLOAD
    onDrop(pictureFiles, pictureDataURLs) {
        if (pictureFiles.length > 0) {
            this.setState({ image: pictureFiles[0] })
        } else {
            this.setState({ image: null })
        }

    }
    //SET LOGO 
    setLogo(image) {
        this.setState({ isLoading: true, image: null })
        let formdata = new FormData();
        formdata.append('file', image)
        formdata.append('session_token', window.sessionStorage.getItem('session_token'))
        formdata.append('id_scat', this.id_scat)
        return fetch('https://ec.mydzstore.com/api/uploadscatimg', {
            method: 'POST',
            body: formdata
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.setState({ isLoading: false })
                this.showNotification(data)
                this.getLogo()
            })
            .catch((error) => console.error(error));
    }
    //GET LOGO
    getLogo() {
        return fetch('https://ec.mydzstore.com/api/getscatimg', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_scat: this.id_scat
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                    this.setState({ isLoading: false, logo: require("assets/img/logo.png").default })
                }
                else {
                    if (data.logo_link === null) {
                        this.setState({ isLoading: false, logo: require("assets/img/logo.png").default })
                    }
                    else {
                        this.setState({ isLoading: false, logo: data.logo_link })
                    }
                }
            })
            .catch((error) => console.error(error));
    }
    //DISPLAY LOADER
    displayLoader() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    componentDidMount() {
        this.getLogo()
    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle tag="h5">{this.lib_scat} / {this.lib_scat_ar}</CardTitle>
                </CardHeader>
                <CardBody>
                    <p>{dict[this.lang].CatImage}</p>
                    <Row>
                        <Col md="6">
                            <div>
                                {this.state.logo !== null && <img style={{ maxHeight: 200 }} src={this.state.logo} alt="Logo" />}
                            </div>
                        </Col>
                        <Col md="6" className="d-flex justify-content-center flex-column">
                            {this.displayLoader()}
                            {!this.state.isLoading &&
                                <ImageUploader
                                    withPreview={true}
                                    withIcon={true}
                                    buttonText={dict[this.lang].importImage}
                                    buttonClassName="btn-primary"
                                    onChange={this.onDrop}
                                    imgExtension={[".jpg", ".gif", ".png"]}
                                    maxFileSize={200000}
                                    label="Max 200 Ko. Formats:jpg, png"
                                    singleImage={true}
                                    fileTypeError={dict[this.lang].fileTypeError}
                                    fileSizeError={dict[this.lang].fileSizeError}
                                />
                            }
                            {this.state.image !== null &&
                                <Button onClick={() => this.setLogo(this.state.image)} className="btn-round btn-sm" color="primary">{dict[this.lang].btnUploadLogoText}</Button>
                            }

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default SCatUploader;
