import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ProdInfos from '../components/Products/ProdInfos';
import ProdPics from '../components/Products/ProdPics';
import ProdVariants from '../components/Products/ProdVariants';
import '../assets/css/myshop.css';

class ProdFiche extends Component {
  constructor(props) {
    super(props)
    this.notifRef = React.createRef()
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.state = {
      id_prod: this.props.match.params.id_prod
    }
  }
  //SHOW NOTIFICATION FUNCTION
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  //SET ID PRODUCT
  setIdProd = (id_prod) => {
    this.setState({ id_prod: id_prod })
  }
  render() {
    const dirLang = this.lang === "ar" ? "arabic" : ""
    return (
      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row className={dirLang}>

          {/* DISAPLY PRODUCT INFOS */}
          <Col md="1" className="d-flex justify-content-center align-items-center">
            <div className="steps one-tier">1/3</div>
          </Col>
          {this.state.id_prod !== "add" &&
            <Col md="11" className="mb-4">
              <ProdInfos showNotification={this.showNotification} setIdProd={this.setIdProd} id_prod={this.state.id_prod} />
            </Col>
          }
          {this.state.id_prod === "add" &&
            <Col md="11" className="mb-4">
              <ProdInfos showNotification={this.showNotification} setIdProd={this.setIdProd} id_prod={this.state.id_prod} />
            </Col>
          }

          {/* DISPLAY PICTURES PRODUCT */}
          {this.state.id_prod !== "add" &&
            <Col md="1" className="d-flex justify-content-center align-items-center">
              <div className="steps two-tier">2/3</div>
            </Col>}

          {this.state.id_prod !== "add" &&
            <Col md="11" className="mb-4">
              <ProdPics showNotification={this.showNotification} id_prod={this.state.id_prod} />
            </Col>
          }

          {/* DISPLAY PRODUCT'S VARIANTS */}
          {this.state.id_prod !== "add" &&
            <Col md="1" className="d-flex justify-content-center align-items-center">
              <div className="steps complete">3/3</div>
            </Col>}

          {this.state.id_prod !== "add" &&
            <Col md="11">
              <ProdVariants showNotification={this.showNotification} id_prod={this.state.id_prod} />
            </Col>
          }
        </Row>
      </div>
    );
  }
}

export default ProdFiche;
