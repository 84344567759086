import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, FormGroup, CustomInput, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';

class Delivery extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.state = {
            appId: "",
            apiToken: "",
            deliveryState: false,
            yalidineState: false,
            yalidineOfferState: true,
            offerData: [],
            api_disabled: true,
            isLoading: true
        };
    }

    getDeliveryState() {
        return fetch('https://ec.mydzstore.com/api/getdeliverystate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    let param_delivery = data.param_delivery
                    let deliveryState = param_delivery.delivery_enabled === "1" ? true : false
                    let yalidineState = param_delivery.api_yalidine === "1" ? true : false
                    this.setState({ deliveryState: deliveryState, yalidineState: yalidineState, isLoading: false, appId: param_delivery.yalidine_appid, apiToken: param_delivery.yalidine_token })
                }

            })
            .catch((error) => console.error(error));
    }

    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //HANDLE SWITCH
    handleSwitch(event) {
        if (event.target.id === "delivery") {
            this.setState({ deliveryState: !this.state.deliveryState })
        }
        else if (event.target.id === "api_yalidine") {
            this.setState({ yalidineState: !this.state.yalidineState })
        }
    }
    //ENABLE DELIVERY
    enableDelivery(event) {
        return fetch('https://ec.mydzstore.com/api/handledelivery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                enable: event.target.checked
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    //GET OFFER DATA
    getOfferData() {
        return fetch('https://ec.mydzstore.com/api/offre', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    let api_disabled = data[0].offre === "Pro" ? false : true
                    this.setState({ offerData: data[0], api_disabled: api_disabled })
                }
            })
            .catch((error) => console.error(error));
    }
    //SET FORM DATA
    setFormData(event) {
        if (event.target.id === "app_id") {
            this.setState({ appId: event.target.value })
        } else if (event.target.id === "api_token") {
            this.setState({ apiToken: event.target.value })
        }
    }
    //DISPLAY DELIVERY FEES
    displayDeliveryFees() {
        return (
            <Col md="12">
                <Link to="delivery/fees"><Button className="btn-primary btn-round">{dict[this.lang].fee}</Button></Link>
            </Col>
        )
    }
    //SET YALIDINE API
    setYalidineApi() {
        return fetch('https://ec.mydzstore.com/api/setyalidineapi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                enable: this.state.yalidineState,
                app_id: this.state.appId,
                api_token: this.state.apiToken,

            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getOfferData()
        this.getDeliveryState()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content" >
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card style={{ height: '100%' }}>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                {/* <CardTitle tag="h5">{dict[this.lang].categories}</CardTitle> */}
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12">
                                        <blockquote>
                                            <p className="blockquote blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].deliveryModeText }}></p>
                                        </blockquote>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <div>
                                                <CustomInput type="switch" id="delivery" name="delivery" label={dict[this.lang].enableDelivery} inline onChange={(event) => { this.handleSwitch(event); this.enableDelivery(event) }} checked={this.state.deliveryState} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {
                                        this.state.deliveryState &&
                                        this.displayDeliveryFees()
                                    }
                                </Row>
                                <hr></hr>
                                <Row>
                                    {/* API YALIDINE */}
                                    <Col md="12">
                                        <blockquote>
                                            <p className="blockquote blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].yalidineApiText }}></p>
                                        </blockquote>
                                    </Col>
                                    <Col md="12" className="d-flex align-items-center">
                                        <CustomInput disabled={this.state.api_disabled} type="switch" id="api_yalidine" name="api_yalidine" label={dict[this.lang].enableApiYalidine} inline onChange={(event) => this.handleSwitch(event)} checked={this.state.yalidineState} />
                                    </Col>
                                    {
                                        this.state.offerData.offre !== "Pro" &&

                                        <Col md="12">
                                            <Link to={
                                                {
                                                    pathname: "/upgrade",
                                                }
                                            }>
                                                <Button className="btn btn-round btn-primary mr-2">
                                                    <i className="nc-icon nc-spaceship"></i> {dict[this.lang].migrate}
                                                </Button>
                                            </Link>
                                        </Col>
                                    }

                                    {
                                        this.state.offerData.offre === "Pro" &&
                                        <Col md="4">
                                            <FormGroup>
                                                <label htmlFor="app_id">
                                                    API-ID
                                                </label>
                                                <Input disabled={!this.state.yalidineState} type="text" id="app_id" onChange={(event) => this.setFormData(event)} defaultValue={this.state.appId} />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        this.state.offerData.offre === "Pro" &&

                                        <Col md="4">
                                            <FormGroup>
                                                <label htmlFor="api_token">
                                                    API-TOKEN
                                                </label>
                                                <Input disabled={!this.state.yalidineState} type="text" id="api_token" onChange={(event) => this.setFormData(event)} defaultValue={this.state.apiToken} />
                                            </FormGroup>
                                        </Col>

                                    }
                                    {
                                        this.state.offerData.offre === "Pro" &&

                                        <Col md="12">
                                            <Button className="btn-primary btn-round" onClick={() => this.setYalidineApi()}>{dict[this.lang].save}</Button>
                                        </Col>

                                    }

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Delivery;
