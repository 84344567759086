import MyShop from "views/MyShop.js";
import UpgradeToPro from "views/Upgrade.js";

var routes = [
    {
        path: "/myshop",
        name: "Ma boutique",
        name_ar: "متجري",
        icon: "nc-icon nc-shop",
        component: MyShop,
    },
    {
        pro: true,
        path: "/upgrade",
        name: "Migrer vers Pro",
        name_ar: "الانتقال إلى Pro",
        icon: "nc-icon nc-spaceship",
        component: UpgradeToPro,

    },
];
export default routes;
