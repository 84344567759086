import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, ButtonGroup, CustomInput, FormGroup, Input } from "reactstrap";
import dict from '../../utils/lang';
import Select from 'react-select'
import "assets/css/myshop.css"
import ProdVariantsItem from './ProdVariantsItem'

let sizeList = [
    { value: "XS", label: "XS" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "XXL", label: "XXL" },
    { value: "3XL", label: "3XL" },
    { value: "4XL", label: "4XL" },
    { value: "5XL", label: "5XL" },
    { value: "Standard", label: "Standard" }
]
for (let i = 18; i <= 62; i++) {
    sizeList.push({ value: i, label: i })
}
let feetList = [];
for (let i = 1; i <= 55; i++) {
    feetList.push({ value: i, label: i })
}

const volumeList = [
    { value: "30ml", label: "30 ml" },
    { value: "50ml", label: "50 ml" },
    { value: "70ml", label: "70 ml" },
    { value: "80ml", label: "80 ml" },
    { value: "100ml", label: "100 ml" },
    { value: "120ml", label: "120 ml" },
    { value: "200ml", label: "200 ml" },
    { value: "250ml", label: "250 ml" },
    { value: "300ml", label: "300 ml" },
    { value: "05l", label: "0.5 L" },
    { value: "1l", label: "1 L" },
    { value: "2l", label: "2 L" },
    { value: "5l", label: "5 L" },
]
const weightList = [
    { value: "50g", label: "50 g" },
    { value: "100g", label: "100 g" },
    { value: "250g", label: "250 g" },
    { value: "05g", label: "500 g" },
    { value: "1kg", label: "1 Kg" },
    { value: "2kg", label: "2 Kg" },
    { value: "5kg", label: "5 Kg" },
]

class ProdVariants extends Component {
    constructor(props) {
        super(props);
        this.form = {}
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.showNotification = this.props.showNotification.bind(this)
        this.id_prod = this.props.id_prod
        this.firstValSelected = [];
        this.secondValSelected = [];
        this.state = {
            promoState: false,
            colorsList: [],
            variantsList: [],
            firstOption: null,
            secondOption: null,
            editDisabled: true,
            prix_variant_promo: 0,
            firstVal: [],
            secondVal: [],
        };
    }
    firstOptionSelected(option) {
        this.setState({ firstOption: option, secondOption: null })
        option !== this.state.firstOption && (this.firstValSelected = [])
        option !== this.state.firstOption && (this.secondValSelected = [])
    }
    secondOptionSelected(option) {
        this.setState({ secondOption: option })
        option !== this.state.secondOption && (this.secondValSelected = [])
    }
    setFirstForm(obj) {
        this.firstValSelected = obj
        this.setState({ firstVal: obj })
    }
    setSecondForm(obj) {
        this.secondValSelected = obj
        this.setState({ secondVal: obj })
    }
    //DISPLAT OPTIONS LIST
    displayOptions() {
        const colourStyles = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = data.value;
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? null
                        : isSelected
                            ? color
                            : isFocused
                                ? color
                                : null,
                    color: isFocused ? 'black' : color,
                    cursor: isDisabled ? 'not-allowed' : 'default',

                    ':active': {
                        ...styles[':active'],
                        backgroundColor:
                            !isDisabled && (isSelected ? color : color),
                    },
                };
            },
            multiValue: (styles, { data }) => {
                const color = data.value;
                return {
                    ...styles,
                    backgroundColor: color
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: 'black',
            }),
            multiValueRemove: (styles, { data }) => {
                const colorLight = this.LightenColor(data.value, 10)
                return {
                    ...styles,
                    color: 'black',
                    ':hover': {
                        backgroundColor: colorLight,
                        color: 'white',
                    },
                }
            },
            menu: (provided, state) => ({
                ...provided,
                zIndex: 99,
            }),
        };
        const colourStyles2 = {
            menu: (provided, state) => ({
                ...provided,
                zIndex: 99,
            }),
        };
        return (
            <div>
                {/* ************************************************************************************* */}
                {/* FISRT OPTION */}
                {/* ************************************************************************************* */}
                <Row>
                    <Col md="12" className="d-flex align-items-center justify-content-between">
                        <p>{dict[this.lang].firstOptionAsk}</p>
                        <ButtonGroup style={{ direction: 'ltr' }}>
                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === 'color'} onClick={() => this.firstOptionSelected('color')}>{dict[this.lang].color}</Button>

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === 'size'} onClick={() => this.firstOptionSelected('size')}>{dict[this.lang].size}</Button>

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === 'feet'} onClick={() => this.firstOptionSelected('feet')}>{dict[this.lang].feet}</Button>

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === 'volume'} onClick={() => this.firstOptionSelected('volume')}>{dict[this.lang].volume}</Button>

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === 'weight'} onClick={() => this.firstOptionSelected('weight')}>{dict[this.lang].weight}</Button>

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.firstOption === null} onClick={() => this.firstOptionSelected(null)}>{dict[this.lang].any}</Button>
                        </ButtonGroup>
                    </Col>

                    {
                        this.state.firstOption === 'color' &&
                        <Col md="6">
                            <small>{dict[this.lang].variantColorAsk}</small>
                            <Select isMulti={true} value={this.state.firstVal} options={this.state.colorsList} onChange={(obj) => this.setFirstForm(obj)} styles={colourStyles} closeMenuOnSelect={false} noOptionsMessage={() => dict[this.lang].noColorOptions} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.firstOption === "size" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantSizeAsk}</small>
                            <Select isMulti={true} value={this.state.firstVal} options={sizeList} onChange={(obj) => this.setFirstForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.firstOption === "feet" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantSizeAsk}</small>
                            <Select isMulti={true} value={this.state.firstVal} options={feetList} onChange={(obj) => this.setFirstForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.firstOption === "volume" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantVolumeAsk}</small>
                            <Select isMulti={true} value={this.state.firstVal} options={volumeList} onChange={(obj) => this.setFirstForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.firstOption === "weight" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantWeightAsk}</small>
                            <Select isMulti={true} value={this.state.firstVal} options={weightList} onChange={(obj) => this.setFirstForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                </Row>
                <hr></hr>
                {/* ************************************************************************************* */}
                {/* SECOND OPTION*/}
                {/* ************************************************************************************* */}
                <Row>
                    <Col md="12" className="d-flex align-items-center justify-content-between">
                        <p>{dict[this.lang].secondOptionAsk}</p>
                        <ButtonGroup style={{ direction: 'ltr' }}>
                            {this.state.firstOption !== 'color' &&
                                <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === 'color'} onClick={() => this.secondOptionSelected('color')}>{dict[this.lang].color}</Button>
                            }


                            {this.state.firstOption !== 'size' &&
                                <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === 'size'} onClick={() => this.secondOptionSelected('size')}>{dict[this.lang].size}</Button>}

                            {this.state.firstOption !== 'feet' &&
                                <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === 'feet'} onClick={() => this.secondOptionSelected('feet')}>{dict[this.lang].feet}</Button>}

                            {this.state.firstOption !== 'volume' &&
                                <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === 'volume'} onClick={() => this.secondOptionSelected('volume')}>{dict[this.lang].volume}</Button>}

                            {this.state.firstOption !== 'weight' &&
                                <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === 'weight'} onClick={() => this.secondOptionSelected('weight')}>{dict[this.lang].weight}</Button>}

                            <Button className="btn-round btn-outline-primary btn-sm" active={this.state.secondOption === null} onClick={() => this.secondOptionSelected(null)}>{dict[this.lang].any}</Button>
                        </ButtonGroup>
                    </Col>
                    {
                        this.state.secondOption === 'color' &&
                        <Col md="6">
                            <small>{dict[this.lang].variantColorAsk}</small>
                            <Select isMulti={true} value={this.state.secondVal} options={this.state.colorsList} onChange={(obj) => this.setSecondForm(obj)} styles={colourStyles} closeMenuOnSelect={false} noOptionsMessage={() => dict[this.lang].noColorOptions} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.secondOption === "size" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantSizeAsk}</small>
                            <Select isMulti={true} value={this.state.secondVal} options={sizeList} onChange={(obj) => this.setSecondForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.secondOption === "feet" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantSizeAsk}</small>
                            <Select isMulti={true} value={this.state.secondVal} options={feetList} onChange={(obj) => this.setSecondForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.secondOption === "volume" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantVolumeAsk}</small>
                            <Select isMulti={true} value={this.state.secondVal} options={volumeList} onChange={(obj) => this.setSecondForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                    {
                        this.state.secondOption === "weight" &&
                        <Col md="6">
                            <small>{dict[this.lang].variantWeightAsk}</small>
                            <Select isMulti={true} value={this.state.secondVal} options={weightList} onChange={(obj) => this.setSecondForm(obj)} styles={colourStyles2} closeMenuOnSelect={false} placeholder={dict[this.lang].selectOption} />
                        </Col>
                    }
                </Row>
                <hr></hr>
                <Row>
                    <Col md="12">
                        <Button className="btn-primary btn-round" onClick={() => this.generateVariants()}>{dict[this.lang].generateVariants}</Button>
                    </Col>
                </Row>
            </div >
        )

    }
    //LOAD COLORS FROM API
    async loadColors() {
        try {
            const reponse = await fetch('https://ec.mydzstore.com/api/getcolors', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token')
                })
            });
            const data = await reponse.json();
            if (data.reponse === "false") {
                this.showNotification(data);
            }
            else {
                this.setState({ colorsList: data });
            }
        } catch (error) {
            return console.error(error);
        }
    }
    //LIGHTEN COLOR
    LightenColor = (color, percent) => {
        let num = parseInt(color.replace("#", ""), 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;

        let res = (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
        return '#' + res
    };
    //GENERATE VARIANTS
    generateVariants() {
        let variant = []
        if (this.firstValSelected.length === 0) {
            //restore default variant
            this.restoreVariant(this.id_prod)
        }
        else {
            for (let i = 0; i < this.firstValSelected.length; i++) {
                let irow = this.firstValSelected[i]

                if (this.secondValSelected.length > 0) {
                    for (let j = 0; j < this.secondValSelected.length; j++) {
                        let jrow = this.secondValSelected[j]

                        let temp_array = {}
                        temp_array[this.state.firstOption] = irow
                        temp_array[this.state.secondOption] = jrow

                        variant.push(temp_array)
                    }
                }
                else {
                    let temp_array = {}
                    temp_array[this.state.firstOption] = irow
                    variant.push(temp_array)
                }

            }
            this.saveVariants(variant)
        }
    }
    //SAVE VARIANT DATAS
    async saveVariants(variant) {
        try {
            const reponse = await fetch('https://ec.mydzstore.com/api/savevariants', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    variant_list: variant,
                    id_prod: this.id_prod,
                    firstOption: this.state.firstOption,
                    secondOption: this.state.secondOption
                })
            });
            const data = await reponse.json();
            if (data.reponse === "false") {
                this.showNotification(data);
            }
            else {
                this.showNotification(data);
                //this.setState({ variantsList: data.data });
                this.loadVariants(this.id_prod)
            }
        } catch (error) {
            return console.error(error);
        }
    }
    //LOAD ALL VARIANTS
    loadVariants = async (id_prod) => {
        if (this.state.id_prod !== "add") {
            try {
                const reponse = await fetch('https://ec.mydzstore.com/api/loadvariants', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        session_token: window.sessionStorage.getItem('session_token'),
                        id_prod: id_prod
                    })
                });
                const data = await reponse.json();
                if (data.reponse === "false") {
                    this.showNotification(data);
                }
                else {
                    if (data.variantsList.length > 0) {
                        this.firstValSelected = data.firstValSelected
                        this.secondValSelected = data.secondValSelected
                        this.setState({ variantsList: [] });
                        this.setState({ variantsList: data.variantsList, firstOption: data.firstOption, secondOption: data.secondOption, firstVal: data.firstValSelected, secondVal: data.secondValSelected });
                    }
                }
            } catch (error) {
                return console.error(error);
            }
        }
    }
    //HANDLE SWITCH OPTIONS
    handleSwitch(event) {
        this.setState({ promoState: event.target.checked })
        let promoForm = event.target.checked === true ? 1 : 0
        this.form.promo = promoForm

        if (event.target.checked) {
            if (this.form.prix_variant !== undefined && this.form.prix_variant !== "0" && this.form.taux_promo !== undefined && this.form.taux_promo !== "0") {
                this.setState({ editDisabled: false })
            }
            else {
                this.setState({ editDisabled: true })
            }
        } else {

            this.setState({ editDisabled: true })
        }
    }

    //SET FORM DATA
    setFormData = (event) => {
        if (event.value !== undefined) {
            this.form[event.name] = event.value
            if (event.value === "") {
                delete this.form[event.name]
            }
        } else {
            this.form[event.target.name] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.name]
            }
        }

        if (this.form.promo === true) {
            if (this.form.prix_variant !== undefined && this.form.prix_variant !== "0" && this.form.taux_promo !== undefined && this.form.taux_promo !== "0") {
                this.setState({ editDisabled: false })
            }
            else {
                this.setState({ editDisabled: true })
            }
        } else {
            if (this.form.prix_variant !== undefined && this.form.prix_variant !== "0") {
                this.setState({ editDisabled: false })
            }
            else {
                this.setState({ editDisabled: true })
            }
        }
    }
    //RESTORE DEFAULT VARIANT
    async restoreVariant(id_prod) {
        try {
            const reponse = await fetch('https://ec.mydzstore.com/api/restoredefaultvariant', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    id_prod: id_prod
                })
            });
            const data = await reponse.json();
            if (data.reponse === "false") {
                this.showNotification(data);
            }
            else {
                this.showNotification(data);
                //this.setState({ variantsList: data.variantsList });
                this.loadVariants(id_prod)
            }
        } catch (error) {
            return console.error(error);
        }
    }
    //HANDLE PROMO
    handlePromo(event) {
        if (event.target.name === "taux_promo" && event.target.value <= 100 && event.target.value !== "" && this.form.prix_variant !== "" && this.form.prix_variant !== "0" && this.form.prix_variant !== undefined) {
            let tx = parseInt(event.target.value)
            let prix_variant = parseInt(this.form.prix_variant)
            let prix_variant_promo = prix_variant - (prix_variant * tx) / 100
            this.form.prix_variant_promo = prix_variant_promo
            this.setState({ prix_variant_promo: prix_variant_promo })
        }
    }
    //APPLY TO ALL VARIANTS
    applyAllVariants(id_prod) {
        return fetch('https://ec.mydzstore.com/api/applyallvariants', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_prod: id_prod,
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.showNotification(data)
                    this.loadVariants(this.id_prod)
                }
            })
            .catch((error) => console.error(error));
    }
    //MOUNT COMPONENT
    componentDidMount() {
        this.loadColors()
        this.loadVariants(this.id_prod)
    }

    render() {
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].prodVariants}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="12">
                            <blockquote>
                                <p className="blockquote blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].variantText }}></p>
                            </blockquote>
                        </Col>
                    </Row>
                    {this.displayOptions()}

                    {
                        this.state.variantsList.length > 1 &&
                        <blockquote className="blockquote blockquote-danger">
                            <p className="blockquote-info" dangerouslySetInnerHTML={{ __html: dict[this.lang].inputGroupVariantText }}></p>
                            <Row>
                                <Col md="3">
                                    <CustomInput type="switch" name="promo" id="z" label={dict[this.lang].enablePromo} inline onChange={(event) => this.handleSwitch(event)} checked={this.state.promoState} />
                                </Col>
                                {/* <Col md="2">
                                    <FormGroup>
                                        <Label>{dict[this.lang].qtToAdd}</Label>
                                        <Input type="number" name="qt" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col> */}

                                <Col md="2">
                                    <FormGroup>
                                        <label htmlFor="prix_variant">
                                            {dict[this.lang].price + " (" + dict[this.lang].currency + ")"}
                                        </label>
                                        <Input type="number" name="prix_variant" onChange={(event) => this.setFormData(event)} />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <label htmlFor="taux_promo">
                                            {dict[this.lang].promoTx} (%)
                                        </label>
                                        <Input type="number" name="taux_promo" onChange={(event) => { this.setFormData(event); this.handlePromo(event) }} disabled={!this.state.promoState} max={100} />
                                    </FormGroup>
                                </Col>
                                <Col md="2">
                                    <FormGroup>
                                        <label htmlFor="prix_variant_promo">
                                            {dict[this.lang].pricePromo + " (" + dict[this.lang].currency + ")"}
                                        </label>
                                        <p style={{ fontSize: 18 }} className="text-danger">{this.state.prix_variant_promo}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="2" className="d-flex">
                                    <Button disabled={this.state.editDisabled} onClick={() => this.applyAllVariants(this.id_prod)} className="btn-sm btn-icon btn-round" color="success"><i className="nc-icon nc-check-2" /></Button>
                                </Col>
                            </Row>
                        </blockquote>
                    }
                    {
                        this.state.variantsList.length > 0 &&
                        this.state.variantsList.map((variant) => {
                            return (
                                <div key={variant.id_variant}>
                                    <ProdVariantsItem
                                        key={variant.id_variant}
                                        variant={variant}
                                        loadVariants={this.loadVariants}
                                        showNotification={this.showNotification}
                                    />
                                    <hr></hr>
                                </div>
                            )
                        })
                    }
                </CardBody >
            </Card >
        );
    }
}

export default ProdVariants;
