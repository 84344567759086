import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button } from "reactstrap";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

class Products extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            prodList: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    loadProducts() {
        return fetch('https://ec.mydzstore.com/api/getproducts', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ prodList: data.prodList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST PRODUCTS IN TABLE
    listProducts() {
        if (this.state.prodList.length === 0) {
            return (
                <p>Vous n'avez encore ajouté aucun produit</p>
            )
        } else {
            return (
                <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            <th></th>
                            <th>{dict[this.lang].reference}</th>
                            <th>{dict[this.lang].designation}</th>
                            <th>{dict[this.lang].categories}</th>
                            <th>{dict[this.lang].subCategories}</th>
                            <th className="text-right">{dict[this.lang].price}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.prodList.map((value, index) => {
                            console.log(value.warning_stock);
                            let stockWarnClass = ""
                            if (value.warning_stock === 1) {
                                stockWarnClass = 'text-danger'
                            }
                            else if (value.warning_stock === 2) {
                                stockWarnClass = 'text-warning'
                            }
                            let promo = value.promo === 1 ? <span className="badge badge-warning">promo</span> : ''
                            return (
                                <tr key={index} className={stockWarnClass}>
                                    <td><img style={{ height: 50, }} src={value.img_path} alt={value.id_photo}></img><br></br>{promo}</td>
                                    <td>{value.id_prod}</td>
                                    <td>{value.nom_prod}</td>
                                    <td>{value.lib_cat}</td>
                                    <td>{value.lib_scat}</td>
                                    <td className="text-right">{value.prix_variant} {dict[this.lang].currency}</td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/products/product-form/" + value.id_prod,
                                                id_prod: value.id_prod
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                        <Link to={
                                            {
                                                pathname: "/products/product-stock/" + value.id_prod,
                                                id_prod: value.id_prod
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-info mr-2">
                                                <i className="nc-icon nc-box-2"></i>
                                            </Button>
                                        </Link>
                                        <Button className="btn-sm btn-round btn-icon btn-outline-danger mr-2" onClick={() => this.removeProduct(value.id_prod)}>
                                            <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //REMOVE PRODUCT BY ID
    removeProduct(id_prod) {
        return fetch('https://ec.mydzstore.com/api/delproduct', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_prod: id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.loadProducts()
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadProducts()
    }

    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12" className="d-flex justify-content-center">
                        <a style={{ fontSize: 16 }} target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=1AQyvRZGKnc">{dict[this.lang].howToAddProduct}</a>
                    </Col>
                    <Col md="6">
                        <Link to={
                            { pathname: "/products/product-form/add" }
                        }><Button className="btn-round" color="primary">{dict[this.lang].addProd}</Button></Link>
                    </Col>
                    <Col md="6">
                        <Link to={
                            { pathname: "/products/categories" }
                        }><Button className="btn-round float-right" color="primary">{dict[this.lang].categories}</Button></Link>
                    </Col>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].products}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listProducts()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Products;
