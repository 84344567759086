import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from "reactstrap";
import dict from '../utils/lang';
import reactCSS from 'reactcss'

class MyShopColors extends Component {
    constructor(props) {
        super(props);

        this.showNotification = this.props.showNotification
        this.lang = window.sessionStorage.getItem('lang')

        this.state = {
            displayColorPickerNavBg: false,
            displayColorPickerNavText: false,
            displayColorPickerMenuBg: false,
            displayColorPickerMenuText: false,
            displayPrimaryColorPicker: false,
            displaySecondaryColorPicker: false,
            colorNavBg: {
                hex: ''
            },
            colorNavText: {
                hex: ''
            },
            colorMenuBg: {
                hex: ''
            },
            colorMenuText: {
                hex: ''
            },
            primaryColor: {
                hex: ''
            },
            secondaryColor: {
                hex: ''
            },
        };
    }
    handleClick = (element) => {
        switch (element) {
            case "navbg":
                this.setState({ displayColorPickerNavBg: !this.state.displayColorPickerNavBg })
                break;
            case "navtxt":
                this.setState({ displayColorPickerNavText: !this.state.displayColorPickerNavText })
                break;
            case "menubg":
                this.setState({ displayColorPickerMenuBg: !this.state.displayColorPickerMenuBg })
                break;
            case "menutxt":
                this.setState({ displayColorPickerMenuText: !this.state.displayColorPickerMenuText })
                break;
            case "pcolor":
                this.setState({ displayPrimaryColorPicker: !this.state.displayPrimaryColorPicker })
                break;
            case "scolor":
                this.setState({ displaySecondaryColorPicker: !this.state.displaySecondaryColorPicker })
                break;
            default:
                break;
        }
    };
    handleClose = () => {
        this.setState({ displayColorPickerNavBg: false, displayColorPickerNavText: false, displayColorPickerMenuBg: false, displayColorPickerMenuText: false, displaySecondaryColorPicker: false, displayPrimaryColorPicker: false })
    }

    handleChangeNavBg = (color) => {
        this.setState({ colorNavBg: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "navbg",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    handleChangeNavTxt = (color) => {
        this.setState({ colorNavText: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "navtxt",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    handleChangeMenuBg = (color) => {
        this.setState({ colorMenuBg: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "menubg",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    handleChangeMenuTxt = (color) => {
        this.setState({ colorMenuText: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "menutxt",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    handleChangePrimaryColor = (color) => {
        this.setState({ primaryColor: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "pcolor",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    handleChangeSecondaryColor = (color) => {
        this.setState({ secondaryColor: color })
        return fetch('https://ec.mydzstore.com/api/setcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                element: "scolor",
                color: color.hex
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    };
    getColors() {
        return fetch('https://ec.mydzstore.com/api/getcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === true) {
                    this.setState({
                        colorNavBg: { hex: data.colorNavBg },
                        colorNavText: { hex: data.colorNavText },
                        colorMenuBg: { hex: data.colorMenuBg },
                        colorMenuText: { hex: data.colorMenuText },
                        primaryColor: { hex: data.primaryColor },
                        secondaryColor: { hex: data.secondaryColor },
                    })
                } else {
                    this.showNotification(data)
                }

            })
            .catch((error) => console.error(error));
    }
    resetColors() {
        return fetch('https://ec.mydzstore.com/api/resetcustomcolor', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getColors()
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getColors()
    }
    render() {
        const styles = reactCSS({
            'default': {
                colorNavBg: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.colorNavBg.hex
                },
                colorNavText: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.colorNavText.hex
                },
                colorMenuBg: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.colorMenuBg.hex
                },
                colorMenuText: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.colorMenuText.hex
                },
                primaryColor: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.primaryColor.hex
                },
                secondaryColor: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.secondaryColor.hex
                },
                swatch: {
                    padding: '5px',
                    background: '#E9E9E9',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    top: 0,
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle tag="h5">{dict[this.lang].shopColors}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].primaryColor}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("pcolor")}>
                                    <div style={styles.primaryColor} />
                                </div>
                                {this.state.displayPrimaryColorPicker ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.primaryColor} onChangeComplete={this.handleChangePrimaryColor} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].secondaryColor}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("scolor")}>
                                    <div style={styles.secondaryColor} />
                                </div>
                                {this.state.displaySecondaryColorPicker ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.secondaryColor} onChangeComplete={this.handleChangeSecondaryColor} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].colorNavBg}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("navbg")}>
                                    <div style={styles.colorNavBg} />
                                </div>
                                {this.state.displayColorPickerNavBg ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.colorNavBg} onChangeComplete={this.handleChangeNavBg} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].colorNavText}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("navtxt")}>
                                    <div style={styles.colorNavText} />
                                </div>
                                {this.state.displayColorPickerNavText ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.colorNavText} onChangeComplete={this.handleChangeNavTxt} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].colorMenuBg}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("menubg")}>
                                    <div style={styles.colorMenuBg} />
                                </div>
                                {this.state.displayColorPickerMenuBg ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.colorMenuBg} onChangeComplete={this.handleChangeMenuBg} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <p>{dict[this.lang].colorMenuText}</p>
                                <div style={styles.swatch} onClick={() => this.handleClick("menutxt")}>
                                    <div style={styles.colorMenuText} />
                                </div>
                                {this.state.displayColorPickerMenuText ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClose} />
                                    <SketchPicker color={this.state.colorMenuText} onChangeComplete={this.handleChangeMenuTxt} />
                                </div> : null}
                            </div>
                        </Col>
                        <Col md="12" >
                            <Button onClick={() => this.resetColors()} className="btn-outline-danger btn-round w-100">{dict[this.lang].reset}</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default MyShopColors;
