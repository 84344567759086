import React, { Component } from 'react';
import { Row, Col, CustomInput, Button, FormGroup, Input } from "reactstrap";
import dict from '../../utils/lang';

class ProdVariantsItem extends Component {
    constructor(props) {
        super(props);
        this.variant = this.props.variant
        this.loadVariants = this.props.loadVariants
        this.showNotification = this.props.showNotification
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.qt = this.variant.qt_reel
        this.form = {}
        this.state = {
            editDisabled: true,
            promoState: false,
            prix_variant_promo: this.variant.prix_variant_promo
        };
    }
    popForm() {
        this.form.promo = this.variant.promo
        this.form.prix_variant = this.variant.prix_variant
        this.form.prix_variant_promo = this.variant.prix_variant_promo
        this.form.taux_promo = this.variant.taux_promo
        let promo = this.variant.promo === "1" ? true : false
        this.setState({ promoState: promo })
    }
    saveVariant(id_variant) {
        return fetch('https://ec.mydzstore.com/api/updatevariant', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_variant: id_variant,
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.showNotification(data)
                    this.loadVariants(this.variant.id_prod)
                }
            })
            .catch((error) => console.error(error));
    }
    //HANDLE SWITCH OPTIONS
    handleSwitch(event) {
        this.setState({ promoState: event.target.checked })
        let promoForm = event.target.checked === true ? 1 : 0
        this.form.promo = promoForm

        let promo = this.variant.promo === "1" ? true : false
        if (promo !== event.target.checked) {
            this.setState({ editDisabled: false })
        }
        else {
            this.setState({ editDisabled: true })
        }
    }
    //HANDLE PROMO
    handlePromo(event) {
        if (event.target.name === "taux_promo" && event.target.value <= 100 && event.target.value !== "" && this.form.prix_variant !== "" && this.form.prix_variant !== "0" && this.form.prix_variant !== undefined) {
            let tx = parseInt(event.target.value)
            let prix_variant = parseInt(this.form.prix_variant)
            let prix_variant_promo = prix_variant - (prix_variant * tx) / 100
            this.form['prix_variant_promo'] = prix_variant_promo
            this.setState({ prix_variant_promo: prix_variant_promo })
        }
    }
    //SET FORM DATA
    setFormData = (event) => {
        if (event.value !== undefined) {
            this.form[event.name] = event.value
            if (event.value === "") {
                delete this.form[event.name]
            }
        } else {
            this.form[event.target.name] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.name]
            }
        }

        if ((this.form.prix_variant !== undefined && this.form.prix_variant !== "0")) {
            this.setState({ editDisabled: false })
        }
        else {
            this.setState({ editDisabled: true })
        }
    }
    componentDidMount() {
        this.popForm()
    }
    render() {
        let variantLabel = this.variant.second_label === null ? this.variant.first_label : this.variant.first_label + ' / ' + this.variant.second_label
        return (
            <Row className="align-items-center mt-4">
                <Col md="2">
                    <b><p>{variantLabel}</p></b>
                    <p>{dict[this.lang].qtInStock} <b className="text-danger">({this.qt})</b></p>
                </Col>
                <Col md="2">
                    <CustomInput type="switch" id={this.variant.id_variant} name="promo" label={dict[this.lang].enablePromo} inline onChange={(event) => this.handleSwitch(event)} checked={this.state.promoState} />
                </Col>
                {/* <Col md="2">
                    <FormGroup>
                        <Label>{dict[this.lang].qtToAdd}</Label>
                        <Input defaultValue={this.form.qt} type="number" name="qt" onChange={(event) => this.setFormData(event)} />
                    </FormGroup>
                </Col> */}

                <Col md="2">
                    <FormGroup>
                        <label htmlFor="prix_variant">
                            {dict[this.lang].price + " (" + dict[this.lang].currency + ")"}
                        </label>
                        <Input defaultValue={this.variant.prix_variant} type="number" name="prix_variant" onChange={(event) => this.setFormData(event)} />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <label htmlFor="taux_promo">
                            {dict[this.lang].promoTx} (%)
                        </label>
                        <Input defaultValue={this.variant.taux_promo} type="number" name="taux_promo" onChange={(event) => { this.setFormData(event); this.handlePromo(event) }} disabled={!this.state.promoState} max={100} />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <FormGroup>
                        <label htmlFor="prix_variant_promo">
                            {dict[this.lang].pricePromo + " (" + dict[this.lang].currency + ")"}
                        </label>
                        <p style={{ fontSize: 18 }} className="text-danger">{this.state.prix_variant_promo}</p>
                    </FormGroup>
                </Col>
                <Col md="1" className="d-flex">
                    <Button disabled={this.state.editDisabled} onClick={() => this.saveVariant(this.variant.id_variant)} className="btn-sm btn-icon btn-round" color="success"><i className="nc-icon nc-check-2" /></Button>
                </Col>
            </Row>
        );
    }
}

export default ProdVariantsItem;
