import React from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import dict from '../utils/lang';
import UsersWid from "components/Widgets/UsersWid";
import RevenueWid from "components/Widgets/RevenueWid";
import ProductsCount from "components/Widgets/ProductsCount";
import OrdersWaitWid from "components/Widgets/OrdersWaitWid";
import ChartRevenue from "components/Widgets/ChartRevenue";
import ChartSellsCount from "components/Widgets/ChartSellsCount";
import ChartBestSellers from "components/Widgets/ChartBestSellers";
import NotificationAlert from "react-notification-alert";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.notifRef = React.createRef()
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.notifRef = React.createRef()
    this.state = {
      offerData: [],
    };
  }
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  //GET OFFER DATA
  getOfferData() {
    return fetch('https://ec.mydzstore.com/api/offre', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token')
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "false") {
          this.showNotification(data)
        } else {
          this.setState({ offerData: data[0] })
        }
      })
      .catch((error) => console.error(error));
  }
  componentDidMount() {
    this.getOfferData()
  }
  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref={this.notifRef} />
          <Row>
            <Col lg="3" md="6" sm="6">
              <UsersWid />
            </Col>
            <Col lg="3" md="6" sm="6">
              <RevenueWid />
            </Col>
            <Col lg="3" md="6" sm="6">
              <ProductsCount />
            </Col>
            <Col lg="3" md="6" sm="6">
              <OrdersWaitWid />
            </Col>
          </Row>
          {
            this.state.offerData.offre === "Pro" &&
            <Row>
              <Col md="12">
                <ChartRevenue />
              </Col>
            </Row>
          }

          {
            this.state.offerData.offre === "Pro" &&
            <Row>
              <Col md="4">
                <ChartBestSellers />
              </Col>
              <Col md="8">
                <ChartSellsCount />
              </Col>
            </Row>
          }

          {
            this.state.offerData.offre !== "Pro" &&
            <Col md="12" className="d-flex flex-column align-items-center justify-content-center mt-4 pt-4">
              <p>{dict[this.lang].needDhashboard}</p>
              <Link to={
                {
                  pathname: "/upgrade",
                }
              }>
                <Button className="btn btn-round btn-primary mr-2">
                  <i className="nc-icon nc-spaceship"></i> {dict[this.lang].migrate}
                </Button>
              </Link>
            </Col>
          }

        </div>
      </>
    );
  }
}

export default Dashboard;
