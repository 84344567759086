import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Badge } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';

class OrdersArchived extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            ordersList: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD ORDERS
    loadOrders() {
        return fetch('https://ec.mydzstore.com/api/getorders', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                archived: "true"
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ ordersList: data.ordersList, isLoading: false })

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST ORDERS IN TABLE
    listOrders() {
        if (this.state.ordersList.length === 0) {
            return (
                <p>{dict[this.lang].noOrder}</p>
            )
        } else {
            return (
                <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            <th>{dict[this.lang].orderNumber}</th>
                            <th>{dict[this.lang].customer}</th>
                            <th>{dict[this.lang].deliveryMode}</th>
                            <th className="text-right">{dict[this.lang].price}</th>
                            <th>{dict[this.lang].orderState}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.ordersList.map((value, index) => {
                            let deliveryMode = value.type_livraison === "1" ? dict[this.lang].inStore : dict[this.lang].shipping
                            let color = "";
                            switch (value.etat) {
                                case "new":
                                    color = "badge-primary"
                                    break;
                                case "canceled":
                                    color = "badge-secondary"
                                    break;
                                case "preparing":
                                    color = "badge-warning"
                                    break;
                                case "ready":
                                    color = "badge-success"
                                    break;
                                case "shipping":
                                    color = "badge-success"
                                    break;
                                case "received":
                                    color = "badge-success"
                                    break;
                                case "archived":
                                    color = "badge-secondary"
                                    break;

                                default:
                                    break;
                            }
                            return (
                                <tr key={index}>
                                    <td>{value.id_order}</td>
                                    <td>{value.nom} {value.prenom}</td>
                                    <td>{deliveryMode}</td>
                                    <td className="text-right">{value.stotal} {dict[this.lang].currency}</td>
                                    <td className="text-center">
                                        <Badge style={{ fontSize: 13, fontWeight: 'normal' }} className={color}>{dict[this.lang][value.etat]}</Badge>
                                    </td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/orders/details/" + value.id_order,
                                                id_order: value.id_order
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    componentDidMount() {
        this.loadOrders()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">{dict[this.lang].orders}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listOrders()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default OrdersArchived;
