import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Button } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import dict from '../utils/lang';
import Select from 'react-select'

class CouponFiche extends Component {
    constructor(props) {
        super(props)
        this.notifRef = React.createRef()
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.form = {}
        this.state = {
            id_coupon: this.props.match.params.id_coupon,
            coupon: [],
            discount_type: {},
            typeList: [{ value: "discountPercent", label: dict[this.lang].discountPercent }, { value: "discountAmount", label: dict[this.lang].discountAmount }],
            disabled: false,
            date_exp: "",
            discount_value: 0,
            code_coupon: "",
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //SET ID coupon
    setIdCoupon = (id_coupon) => {
        this.setState({ id_coupon: id_coupon })
    }
    //SET FORM DATA
    setFormData = (event) => {
        if (event.value !== undefined) {
            this.form["discount_type"] = event.value
            if (event.value === "") {
                delete this.form["discount_type"]
            }
        } else {
            this.form[event.target.id] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.id]
            }
        }
    }
    //SAVE COUPON
    saveCoupon() {
        return fetch('https://ec.mydzstore.com/api/savecoupon', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                if (data.id_coupon !== undefined) {
                    this.setIdCoupon(data.id_coupon)
                }
            })
            .catch((error) => console.error(error));
    }
    //GET COUPON DATA
    getCouponData() {
        if (this.state.id_coupon !== "add") {
            return fetch('https://ec.mydzstore.com/api/getcoupondata', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    id_coupon: this.state.id_coupon
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    let coupon = data.couponData[0]
                    const defaultValue = this.state.typeList.find(opt => opt.value === coupon.discount_type);
                    this.setState({ coupon: coupon, discount_type: defaultValue, discount_value: coupon.discount_value, date_exp: coupon.date_exp })
                    this.form.discount_value = coupon.discount_value
                    this.form.discount_type = coupon.discount_type
                    this.form.code_coupon = coupon.code_coupon
                    this.form.date_exp = coupon.date_exp
                })
                .catch((error) => console.error(error));
        }
    }
    //UPDATE COUPON DATA
    updateCoupon() {
        return fetch('https://ec.mydzstore.com/api/savecoupon', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form,
                id_coupon: this.state.id_coupon
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getCouponData()
    }
    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card style={{ height: '100%' }}>
                            <CardHeader>
                                <CardTitle tag="h5">{dict[this.lang].couponCode}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="code_coupon">
                                                {dict[this.lang].couponCode}
                                            </label>
                                            <Input defaultValue={this.state.coupon.code_coupon} type="text" id="code_coupon" onChange={(event) => this.setFormData(event)} style={{ fontWeight: 'bold' }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="">
                                                {dict[this.lang].discountType}
                                            </label>
                                            <Select value={this.state.discount_type} options={this.state.typeList} onChange={(event) => { this.setState({ discount_type: event }); this.setFormData(event); }} isSearchable={false} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="discount_value">
                                                {dict[this.lang].discountValue}
                                            </label>
                                            <Input defaultValue={this.state.coupon.discount_value} type="number" id="discount_value" onChange={(event) => this.setFormData(event)} style={{ fontWeight: 'bold' }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="date_exp">
                                                {dict[this.lang].expireDate}
                                            </label>
                                            <Input defaultValue={this.state.coupon.date_exp} type="date" id="date_exp" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12" className="d-flex justify-content-center">
                                        {
                                            this.state.id_coupon !== "add" &&
                                            <Button disabled={this.state.disabled} onClick={() => this.updateCoupon()} className="btn-round" color="primary">{dict[this.lang].update}</Button>
                                        }
                                        {
                                            this.state.id_coupon === "add" &&
                                            <Button disabled={this.state.disabled} onClick={() => this.saveCoupon()} className="btn-round" color="primary">{dict[this.lang].save}</Button>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default CouponFiche;
