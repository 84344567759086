import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import Loader from '../Loader/Loader'
import dict from '../../utils/lang';

class ProductsCount extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            isLoading: true,
            nbProds: 0,
        }
    }

    getProductsCount() {
        this.setState({ isLoading: true })
        return fetch('https://ec.mydzstore.com/api/getproductscount', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else if (data.reponse === "true") {
                    this.setState({ isLoading: false, nbProds: data.count })
                }
            })
            .catch((error) => console.error(error));
    }

    componentDidMount() {
        this.getProductsCount()
    }

    render() {
        return (
            <Card className="card-stats">
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardBody>
                    <Row>
                        <Col md="4" xs="5">
                            <div className="icon-big text-center icon-primary">
                                <i className="nc-icon nc-tag-content text-primary" />
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="numbers">
                                <p className="card-category">{dict[this.lang].productsCount}</p>
                                <CardTitle tag="p">{this.state.nbProds}</CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <hr />
                    <div className="stats" onClick={() => this.getProductsCount()}>
                        <i className="fas fa-sync-alt" /> {dict[this.lang].refresh}
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default ProductsCount;
